import {
  AppBar,
  Container,
  Toolbar,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

type PlanPageHeaderProps = {
  tab: number;
  onChange: any;
};

export default function PlanPageHeader({ tab, onChange }: PlanPageHeaderProps) {
  const navigate = useNavigate();

  return (
    <AppBar position="static" sx={{ marginBottom: "30px", color: "#fff" }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => navigate("/")}
        >
          <ArrowBackIosNewIcon />
        </IconButton>

        <Container>
          <Tabs
            value={tab}
            onChange={onChange}
            textColor="inherit"
            variant="fullWidth"
            aria-label="Plan Page"
          >
            <Tab label="Contracts" {...a11yProps(0)} />
            <Tab label="Summary" {...a11yProps(1)} />
          </Tabs>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
