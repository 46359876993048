import { Box, Paper, Typography } from "@mui/material";
import { LoadingButton, toast } from "aagent-ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatUnixTimestamp } from "./time_util";
import { patchAPI } from "./authenticator_util";
import { SyntheticEvent, useState } from "react";
import * as types from "./types";
import * as utils from "./ContractUtils";
import * as statusUtils from "./statusUtils";
import Image from "./Image";

function getDisabled(status: types.StatusType) {
  return (
    status === types.StatusType.WORK_IN_PROGRESS ||
    status === types.StatusType.GIVE_UP ||
    status === types.StatusType.DISPUTE
  );
}

function getButtonColor(status: types.StatusType) {
  if (status === types.StatusType.APPROVED) {
    return "success";
  }

  if (status === types.StatusType.DECLINED) {
    return "error";
  }

  return "primary";
}

function ContractItemTitle({ contract }: { contract: types.Contract }) {
  let title = "";
  const status = utils.getLatestStatus(contract);
  switch (status) {
    case types.StatusType.WAIT_APPROVAL:
    case types.StatusType.APPROVED:
    case types.StatusType.REQUEST_INFO:
    case types.StatusType.DISPUTE:
      title = (contract.social_medias[0]?.likes || 0).toString();
      if (!contract.post.skip_receipt) {
        title = `${contract.subtotal} | ${title}`;
      }
      title = "$" + title;
      break;
    case types.StatusType.DECLINED:
      title = "Declined";
      break;
    default:
      title = "Pending";
  }

  return (
    <Typography variant="h2" sx={{ color: "secondary.main" }}>
      {title}
    </Typography>
  );
}

function ImageSection({ s3Images }: { s3Images: string[] }) {
  const dimension = { xs: "60px", sm: "80px", md: "120px" };

  return (
    <Box sx={{ display: "flex", pt: "6px", overflow: "hidden" }}>
      {s3Images.map((path) => (
        <Paper
          key={path}
          sx={{ width: dimension, height: dimension, mx: "4px" }}
        >
          <Image
            path={path}
            s3
            size={types.ImgSizes.md}
            sx={{ width: dimension, height: dimension, objectFit: "contain" }}
          />
        </Paper>
      ))}
    </Box>
  );
}

export default function ContractItem({
  contract,
}: {
  contract: types.Contract;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<types.StatusType>(
    utils.getLatestStatus(contract)
  );
  const readyForDetails = utils.readyForDetails(contract);
  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const admin = searchParams.get("admin");

  const handleApprove = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (status !== types.StatusType.WAIT_APPROVAL) {
      return;
    }

    setLoading(true);
    patchAPI(`/contract/${contract.id}/approve`)
      .then(() => setStatus(types.StatusType.APPROVED))
      .then(() => toast.success("Contract approved"))
      .catch((err) => toast.error(String(err)))
      .finally(() => setLoading(false));
  };

  const handleGiveup = (e: SyntheticEvent) => {
    e.stopPropagation();
    setLoading(true);
    patchAPI(`/contract/${contract.id}/give-up`)
      .then(() => setStatus(types.StatusType.GIVE_UP))
      .then(() => toast.success("Contract given up"))
      .catch((err) => toast.error(String(err)))
      .finally(() => setLoading(false));
  };

  const s3Images = [
    ...contract.receipts.map((receipt) => receipt.image_s3_path),
    ...contract.social_medias.flatMap((media) => media.image_s3_paths),
  ].slice(0, 5);

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        border: "1px solid rgba(238, 234, 248, 0.75)",
        boxShadow: "0px 5px 40px -11.4039px rgba(229, 233, 242, 0.3)",
        borderRadius: "20px",
        padding: "16px",
        my: "10px",
        cursor: "pointer",
      }}
      onClick={() => readyForDetails && navigate(`/contract/${contract.id}`)}
    >
      {/* Top section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Left: title and timestamp */}
        <Box>
          <ContractItemTitle contract={contract} />
          <Typography sx={{ color: "grey.400", fontSize: "14px" }}>
            {contract.post.store.business_name}
          </Typography>
          <Typography sx={{ color: "grey.400", fontSize: "14px" }}>
            {formatUnixTimestamp(contract.last_updated)}
          </Typography>
        </Box>

        {/* Right: action button */}
        <Box>
          <LoadingButton
            variant={
              status === types.StatusType.WORK_IN_PROGRESS
                ? "contained"
                : "outlined"
            }
            loading={loading}
            disabled={getDisabled(status)}
            color={getButtonColor(status)}
            sx={{ borderRadius: "10px", padding: "9px 17px" }}
            onClick={handleApprove}
          >
            <Typography
              variant="h5"
              sx={{ lineHeight: "normal", fontWeight: "bold" }}
            >
              {statusUtils.getLabel(status)}
            </Typography>
          </LoadingButton>

          {admin && status !== types.StatusType.GIVE_UP && (
            <LoadingButton
              sx={{ height: "30px", background: "#ff77ff" }}
              onClick={handleGiveup}
            >
              Give Up
            </LoadingButton>
          )}
        </Box>
      </Box>

      {/* Bottom: Images */}
      {s3Images.length > 0 && readyForDetails && (
        <ImageSection s3Images={s3Images} />
      )}
    </Box>
  );
}
