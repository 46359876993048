import { Box, Typography } from "@mui/material";
import * as types from "./types";

export default function WelcomeBanner({
  merchantInfo,
}: {
  merchantInfo: types.MerchantInfo;
}) {
  const name = `${merchantInfo.first_name} ${merchantInfo.last_name}`;

  return (
    <Box sx={{ marginBottom: "16px" }}>
      <Typography variant="overline">WELCOME BACK</Typography>
      <Typography variant="h1">{name}</Typography>
    </Box>
  );
}
