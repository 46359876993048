import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  LinearProgress,
} from "@mui/material";
import { Link as RouteLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import { PLAN_TYPE_TO_ICON, PLAN_TYPE_TO_LABEL } from "../constants";
import { Card } from "aagent-ui";
import BottomNavigationLayout from "../BottomNavigationLayout";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import WelcomeBanner from "../WelcomeBanner";
import * as types from "../types";

function PlanCard({ plan }: { plan: types.Plan }) {
  const {
    id,
    plan_type,
    plan_name,
    max_contracts_per_month,
    combo_monthly_budget,
  } = plan;

  return (
    <Grid item xs={12}>
      <Link component={RouteLink} to={`/plan/${id}`} underline="none">
        <Card
          sx={{
            borderRadius: "24px",
            padding: "14px 17px",

            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginRight: "28px", fontSize: "34px" }}>
            {PLAN_TYPE_TO_ICON[plan_type]}
          </Box>
          {/* Setting minWidth to prevent the element from squeezing other elements out of the container */}
          {/* https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container */}
          <Box sx={{ flex: 1, minWidth: 0 }}>
            <Typography
              variant="h3"
              sx={{ fontSize: "13px", marginBottom: "4px" }}
            >
              {PLAN_TYPE_TO_LABEL[plan_type]}
            </Typography>
            {/* https://www.digitalocean.com/community/tutorials/css-prevent-line-break */}
            <Typography
              variant="h2"
              color="secondary.dark"
              sx={{
                marginBottom: "4px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {plan_name || "Untitiled"}
            </Typography>

            <Typography color="grey.300" sx={{ fontSize: "12px" }}>
              {plan_type === types.PlanType.COMBO
                ? `Budget: $${combo_monthly_budget} / month`
                : `Limit: ${max_contracts_per_month} contracts / month`}
            </Typography>
          </Box>

          <ArrowForwardIosOutlinedIcon
            sx={{ height: "20px", color: "primary.light" }}
          />
        </Card>
      </Link>
    </Grid>
  );
}

function AddPlanCard() {
  return (
    <Grid item xs={12}>
      <Link component={RouteLink} to={`/add-plan`} underline="none">
        <Card
          sx={{
            borderRadius: "24px",
            padding: "14px 17px",

            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginRight: "28px", fontSize: "34px" }}>
            <span>&#10133;</span>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h3" sx={{ fontSize: "13px" }}></Typography>
            <Typography variant="h2" sx={{ marginBottom: "4px" }}>
              Add new plan
            </Typography>

            <Typography
              color="secondary.main"
              sx={{ fontSize: "13px" }}
            ></Typography>
          </Box>

          <ArrowForwardIosOutlinedIcon
            sx={{ height: "20px", color: "primary.light" }}
          />
        </Card>
      </Link>
    </Grid>
  );
}

type PlansViewProps = {
  plans: types.Plan[];
  merchantInfo: types.MerchantInfo;
};

function PlansView({ plans, merchantInfo }: PlansViewProps) {
  return (
    <Container sx={{ padding: "24px 16px" }}>
      <WelcomeBanner merchantInfo={merchantInfo} />

      {plans.length > 0 && (
        <Typography
          variant="overline"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "primary.light",
            display: "block",
            margin: "20px 0",
          }}
        >
          YOUR ACTIVE PLANS
        </Typography>
      )}

      <Grid container rowSpacing={2}>
        {plans.map((plan) => (
          <PlanCard key={plan.id} plan={plan} />
        ))}

        {/* Add plan */}
        <AddPlanCard />
      </Grid>
    </Container>
  );
}

export default function Plans() {
  const [plans, setPlans] = useState<types.Plan[]>([]);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [merchantInfo, setMerchantInfo] = useState<types.MerchantInfo>();
  const loadingMerchantInfo = !merchantInfo;

  useEffect(() => {
    fetchAPI(`/merchant/plans`)
      .then((response) => response.json())
      .then((plans) => {
        setPlans(plans);
        setLoadingPlans(false);
      });
  }, []);

  useEffect(() => {
    fetchAPI("/merchant/info")
      .then((response) => response.json())
      .then(setMerchantInfo);
  }, []);

  const loading = loadingPlans || loadingMerchantInfo;

  return (
    <BottomNavigationLayout>
      {loading && <LinearProgress />}
      {!loading && <PlansView plans={plans} merchantInfo={merchantInfo} />}
    </BottomNavigationLayout>
  );
}
