import {
  Box,
  Card,
  CardContent,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import BottomNavigationLayout from "../BottomNavigationLayout";
import ContractItem from "../ContractItem";
import * as utils from "../ContractUtils";

export default function AllContracts() {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState<any[]>([]);

  useEffect(() => {
    fetchAPI(`/merchant/contracts`)
      .then((response) => response.json())
      .then(utils.sortByPriority)
      .then(setContracts)
      .then(() => setLoading(false));
  }, []);

  return (
    <BottomNavigationLayout>
      <Container sx={{ paddingTop: "30px" }}>
        <Typography variant="h1" gutterBottom>
          Contracts
        </Typography>
        <Box m="30px" />

        {loading && <LinearProgress />}

        <Box>
          {!loading && contracts.length === 0 && (
            <Card>
              <CardContent>
                <Typography>
                  Currently you don't have any contracts under this plan yet.
                </Typography>
              </CardContent>
            </Card>
          )}

          {contracts.length > 0 &&
            contracts.map((contract) => (
              <ContractItem key={contract.id} contract={contract} />
            ))}
        </Box>
      </Container>
    </BottomNavigationLayout>
  );
}
