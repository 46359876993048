import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  SwipeableDrawer,
  Link,
} from "@mui/material";
import { Link as RouteLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useEffect, useRef, useState } from "react";
import { getCurrentUser } from "./authenticator_util";
import { Auth } from "aws-amplify";

const pathToTitle: any = {
  "/budget-plan": "Budget Plan",
  "/plans": "Plans",
  "/add-plan": "Select Plan Type",
};

type ParamsType = {
  planType?: string;
};

function getPathTitle(path: string, params: ParamsType): string {
  if (path.startsWith("/add-plan/")) {
    const { planType } = params;
    if (!!planType) {
      return `${planType} Plan`;
    }
  }

  return pathToTitle[path] || "";
}

function pathAtRoot(path: string): boolean {
  return !path || path === "/" || path === "/plans";
}

function MenuDrawer() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // We use mountedRef to prevent calling setState when the component is unmounted.
  // Reference: https://stackoverflow.com/a/66650588
  const mountedRef = useRef(true);
  const setLoggedInIfMounted = (val: boolean) =>
    !!mountedRef.current && setIsLoggedIn(val);

  useEffect(() => {
    getCurrentUser().then(
      () => !isLoggedIn && setLoggedInIfMounted(true),
      () => isLoggedIn && setLoggedInIfMounted(false)
    );
    return () => {
      mountedRef.current = false;
    };
  });

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={() => setDrawerOpen(!drawerOpen)}
      >
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onOpen={() => setDrawerOpen(true)}
        onClose={() => setDrawerOpen(false)}
      >
        <List sx={{ minWidth: "160px" }}>
          <Link component={RouteLink} to="/settings/info" underline="hover">
            <ListItem button>
              <ListItemText primary="Account" />
            </ListItem>
          </Link>
          <Divider />
          <ListItem button>
            <ListItemText primary="Help" />
          </ListItem>
          {isLoggedIn && (
            <ListItem button onClick={() => Auth.signOut()}>
              <ListItemText primary="Logout" />
            </ListItem>
          )}
        </List>
      </SwipeableDrawer>
    </>
  );
}

export default function Header() {
  const location = useLocation();
  const params = useParams();

  // https://reactrouter.com/docs/en/v6/upgrading/v5#use-usenavigate-instead-of-usehistory
  const navigate = useNavigate();
  const path = String(location.pathname);
  const title = getPathTitle(path, params);
  const atRoot = pathAtRoot(path);

  const goUpperLevel = () => {
    if (location.pathname.startsWith("/plan/")) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, marginBottom: "30px" }}>
      <AppBar position="static">
        <Toolbar sx={{ color: "#fff" }}>
          {atRoot && <MenuDrawer />}

          {/* GoBack Icon */}
          {!atRoot && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={goUpperLevel}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          )}

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
