import { Box, Typography } from "@mui/material";
import { Slider } from "aagent-ui";
import { Field } from "formik";

type SliderFieldProp = {
  value: any; // Come from formik
  name: string;
  label: string;
  setFieldValue?: any; // Come from formik
  step: number;
  min: number;
  max: number;
};

export function FreeSliderField({
  name,
  value,
  label,
  step,
  min,
  max,
  setFieldValue,
}: SliderFieldProp) {
  const handleChange =
    setFieldValue && ((e: any) => setFieldValue(name, e.target.value));

  return (
    <>
      <Typography gutterBottom>{`${label}: ${value}`}</Typography>
      <Slider
        name={name}
        step={step}
        min={min}
        max={max}
        onChange={handleChange}
        disabled={!setFieldValue}
        valueLabelDisplay="auto"
        value={value}
      />
    </>
  );
}

export default function SliderField({
  name,
  value,
  label,
  setFieldValue,
  step,
  min,
  max,
}: SliderFieldProp) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "6px",
        }}
      >
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="subtitle1">{value}</Typography>
      </Box>
      <Field
        component={Slider}
        sx={{ padding: 0, "@media (pointer: coarse)": { padding: 0 } }}
        name={name}
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={(e: any) => setFieldValue(name, e.target.value)}
      />
    </>
  );
}
