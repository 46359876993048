export function formatUnixTimestamp(timestamp: number) {
  // https://stackoverflow.com/a/847200
  const date = new Date(timestamp);

  // https://www.w3schools.com/jsref/jsref_tolocalestring.asp
  return date.toLocaleString("en-US", {
    dateStyle: "medium",
    timeStyle: "short",
  });
}
