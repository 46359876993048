import { Box } from "@mui/material";
import { LoadingButton, toast } from "aagent-ui";
import { useFormik } from "formik";
import { useState } from "react";
import { postAPI } from "./authenticator_util";
import StoreFormBody from "./StoreFormBody";
import * as types from "./types";

export default function StoreForm({
  user,
  merchant,
  store,
  onSuccess,
}: {
  user: types.User;
  merchant: types.MerchantInfo;
  store?: types.Store;
  onSuccess?: (store: types.Store) => any;
}) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = (rawValues: any) => {
    const values = {
      ...types.storeSchema.cast(rawValues),
      merchant_id: user.username,
      contact_first_name: merchant.first_name,
      contact_last_name: merchant.last_name,
      email: user.attributes.email,
      phone_number: user.attributes.phone_number,
    };
    setIsSubmitting(true);

    postAPI("/merchant/store", values)
      .then((res) => res.json())
      .then((store: types.Store) => {
        toast.success("Store successfully added");
        !!onSuccess && onSuccess(store);
      })
      .catch((err) => {
        toast.error(String(err));
        setIsSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: store || types.storeSchema.getDefault(),
    validationSchema: types.storeSchema,
    onSubmit,
  });
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <StoreFormBody
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        prefix=""
        handleChange={formik.handleChange}
        setFieldValue={formik.setFieldValue}
      />

      <Box m="30px" />
      <LoadingButton fullWidth type="submit" loading={isSubmitting}>
        Update
      </LoadingButton>
    </Box>
  );
}
