import { Box, Chip, SxProps, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CreditCardIcon from "./CreditCardIcon";
import IconCard from "./IconCard";
import * as types from "./types";
import * as PaymentUtils from "./PaymentUtils";

export function AddPaymentMethod() {
  const navigate = useNavigate();
  return (
    <IconCard
      icon={<CreditCardIcon brand="default" />}
      onClick={() => navigate("/settings/add-card?redirectUrl=/?add-fund=1")}
    >
      <Typography
        sx={{
          color: "text.primary",
          fontSize: "15px",
          fontWeight: "bold",
        }}
      >
        Add Card
      </Typography>
    </IconCard>
  );
}

export default function PaymentMethodUI({
  paymentMethod,
  sx,
  onClick,
  isDefault,
}: {
  paymentMethod: types.PaymentMethod;
  sx?: SxProps;
  onClick?: any;
  isDefault: boolean;
}) {
  const { brand, last4 } =
    PaymentUtils.getPaymentMethodDisplayInfo(paymentMethod);

  return (
    <IconCard sx={sx} icon={<CreditCardIcon brand={brand} />} onClick={onClick}>
      <Box>
        <Typography
          sx={{
            color: "#000",
            fontSize: "15px",
            marginBottom: "6px",
          }}
        >
          {brand.toUpperCase()}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ color: "text.secondary" }}>•••• {last4}</Typography>

          {isDefault && (
            <Chip
              sx={{ marginLeft: "12px" }}
              label="default"
              color="success"
              variant="outlined"
              size="small"
            />
          )}
        </Box>
      </Box>
    </IconCard>
  );
}
