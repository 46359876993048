import { LinearProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import BottomNavigationLayout from "../BottomNavigationLayout";
import AddPaypalCardForm from "../AddPaypalCardForm";
import * as types from "../types";

export default function AddPlan() {
  const [paymentMethods, setPaymentMethods] = useState<types.PaymentMethod[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [cardAdded, setCardAdded] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAPI("/paypal/payment-methods")
      .then((response) => response.json())
      .then(setPaymentMethods)
      .then(() => setLoading(false))
      .catch(alert);
  }, [cardAdded]);

  if (loading) {
    return (
      <BottomNavigationLayout>
        <LinearProgress />
      </BottomNavigationLayout>
    );
  }

  if (paymentMethods.length === 0 && !cardAdded) {
    return (
      <CardWithBottomNavLayout>
        <Typography variant="h1" sx={{ marginBottom: "43px" }}>
          Add Card
        </Typography>
        <AddPaypalCardForm onSuccess={() => setCardAdded(true)} />
      </CardWithBottomNavLayout>
    );
  }

  // We now support COMBO plans only.
  navigate("/add-plan/COMBO");
  return null;
}
