import { Box, Typography } from "@mui/material";
import { PUBLIC_SOCIAL_MEDIAS, SOCIAL_MEDIA_TO_ICON } from "./constants";
import MediaIcon from "./MediaIcon";
import * as types from "./types";

type AcceptedSocialMediasProps = {
  value: types.SocialMedia[];
  onChange: (medias: types.SocialMedia[]) => any;
};

export default function AcceptedSocialMedias({
  value,
  onChange,
}: AcceptedSocialMediasProps) {
  return (
    <Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          ACCEPTED SOCIAL MEDIA
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        {PUBLIC_SOCIAL_MEDIAS.map((media, index) => (
          <Box
            component={MediaIcon}
            sx={{ cursor: "pointer" }}
            key={index}
            icon={SOCIAL_MEDIA_TO_ICON[media]}
            selected={value.includes(media)}
            onClick={() => {
              // Don't allow empty array.
              if (value.length === 1 && value[0] === media) {
                return;
              }
              if (value.includes(media)) {
                onChange(value.filter((m) => m !== media));
              } else {
                onChange([...value, media]);
              }
            }}
          />
        ))}
      </Box>
    </Box>
  );
}
