import {
  Box,
  Container,
  Typography,
  Divider,
  SxProps,
  LinearProgress,
  LinearProgressProps,
  CardContent,
  Chip,
} from "@mui/material";
import { Card, TextField, LoadingButton, toast } from "aagent-ui";
import { ReactNode, useEffect, useState } from "react";
import { fetchAPI, patchAPI } from "./authenticator_util";
import { useFormik, FormikProvider } from "formik";
import SliderField from "./SliderField";
import { PLAN_TYPE_TO_LABEL, SOCIAL_MEDIA_TO_ICON } from "./constants";
import MediaIcon from "./MediaIcon";
import * as types from "./types";

interface PlanSummaryProps {
  plan: types.Plan;
  setPlan: (plan: types.Plan) => void;
}

function AcceptedSocialMediaRow({
  social_medias,
  sx,
}: {
  social_medias: types.SocialMedia[];
  sx?: SxProps;
}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", ...(sx || {}) }}>
      {social_medias.map((media, index) => (
        <MediaIcon
          icon={SOCIAL_MEDIA_TO_ICON[media]}
          selected
          key={index}
          sx={{ margin: "auto 5px" }}
        />
      ))}
    </Box>
  );
}

function TableRow({ left, right }: { left: ReactNode; right: ReactNode }) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "16px 0",
        }}
      >
        <Typography variant="h3" sx={{ color: "primary.dark" }}>
          {left}
        </Typography>
        <Typography
          variant="body1"
          component={Box}
          sx={{ color: "primary.dark", ml: "26px" }}
        >
          {right}
        </Typography>
      </Box>
      <Divider sx={{ borderColor: "secondary.light" }} />
    </>
  );
}

type PlanStatusButtonProps = {
  plan: types.Plan;
  setPlan: any;
};

function PausePlanButton({ plan, setPlan }: PlanStatusButtonProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const handlePausePlan = () => {
    setLoading(true);
    patchAPI(`/plan/${plan.id}/pause`)
      .then((response) => response.json())
      .then(setPlan)
      .catch((err) => toast.error(String(err)))
      .finally(() => setLoading(false));
  };

  return (
    <LoadingButton
      fullWidth
      loading={loading}
      variant="outlined"
      onClick={handlePausePlan}
    >
      Pause
    </LoadingButton>
  );
}

function ResumePlanButton({ plan, setPlan }: PlanStatusButtonProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleResumePlan = () => {
    setLoading(true);
    patchAPI(`/plan/${plan.id}/resume`)
      .then((response) => response.json())
      .then(setPlan)
      .catch((err) => toast.error(String(err)))
      .finally(() => setLoading(false));
  };

  return (
    <LoadingButton
      fullWidth
      loading={loading}
      variant="contained"
      onClick={handleResumePlan}
    >
      Resume
    </LoadingButton>
  );
}

function SummaryForm({ plan, setPlan }: PlanSummaryProps) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<types.PaymentMethod>();

  useEffect(() => {
    if (!plan.payment_method_id) return;

    fetchAPI(`/paypal/payment-method/${plan.payment_method_id}`)
      .then((response) => response.json())
      .then(setPaymentMethod)
      .catch((err) => toast.error(String(err)));
  }, [plan.payment_method_id]);

  const initialValues = {
    plan_name: plan.plan_name,
    max_contracts_per_month: plan.max_contracts_per_month,
    notes: plan.notes || "",
  };

  const updatePlan = (values: typeof initialValues) => {
    if (
      values.plan_name === plan.plan_name &&
      values.max_contracts_per_month === plan.max_contracts_per_month &&
      values.notes === plan.notes
    )
      return;
    setIsUpdating(true);

    patchAPI(`/update-plan/${plan.id}`, values)
      .then(() => setPlan({ ...plan, ...values }))
      .catch((err) => toast.error(String(err)))
      .finally(() => setIsUpdating(false));
  };
  const formik = useFormik({
    initialValues,
    onSubmit: updatePlan,
  });
  const { values, setFieldValue } = formik;
  return (
    <FormikProvider value={formik}>
      <Container
        disableGutters
        component="form"
        noValidate
        sx={{ maxWidth: { xs: "600px" } }}
        onSubmit={formik.handleSubmit}
      >
        <Card sx={{ padding: "30px 26px" }}>
          {/* name */}
          <TextField
            label="Name"
            name="plan_name"
            variant="outlined"
            fullWidth
            value={values.plan_name}
            onChange={formik.handleChange}
          />

          {plan.plan_type !== types.PlanType.COMBO && (
            <>
              <Box sx={{ m: "10px" }}></Box>
              {/* max_contracts_per_month */}
              <SliderField
                label="Max contracts per month"
                name="max_contracts_per_month"
                value={values.max_contracts_per_month}
                setFieldValue={setFieldValue}
                step={1}
                min={0}
                max={50}
              />
            </>
          )}

          <Box sx={{ m: "40px" }}></Box>
          {/* Table of immutable content */}
          <Box>
            <TableRow
              left="LOCATION"
              right={
                <Box sx={{ textAlign: "right" }}>
                  {plan.stores.map((store) => (
                    <Chip id={store.id} label={store.business_name} />
                  ))}
                </Box>
              }
            />
            <TableRow
              left="PLAN TYPE"
              right={PLAN_TYPE_TO_LABEL[plan.plan_type]}
            />
            {plan.plan_type === types.PlanType.COMBO && (
              <TableRow left="COMBO TYPE" right={plan.combo_type} />
            )}
            {plan.plan_type === types.PlanType.COMBO && (
              <TableRow
                left="MONTHLY BUDGET"
                right={plan.combo_monthly_budget}
              />
            )}

            {![types.PlanType.COMBO, types.PlanType.GUEST_V2].includes(
              plan.plan_type
            ) && (
              <TableRow
                left="MAXIMUM REIMBURSEMENT"
                right={plan.max_reimbursement_per_bill}
              />
            )}

            {plan.tiers &&
              plan.plan_type !== types.PlanType.GUEST &&
              plan.plan_type !== types.PlanType.COMBO && (
                <TableRow left="Tiers" right={plan.tiers.join(", ")} />
              )}

            {plan.plan_type !== types.PlanType.GUEST && (
              <TableRow
                left="SOCIAL MEDIA"
                right={
                  <AcceptedSocialMediaRow social_medias={plan.social_medias} />
                }
              />
            )}

            <TableRow
              left="PAYMENT"
              right={
                <Typography>
                  {!!paymentMethod
                    ? `•••• ${paymentMethod.card?.last4}`
                    : "..."}
                </Typography>
              }
            />

            {/* SPECIAL REQUEST */}
            <TableRow
              left="SPECIAL REQUEST"
              right={
                <TextField
                  name="notes"
                  variant="standard"
                  value={values.notes}
                  onChange={formik.handleChange}
                />
              }
            />
          </Box>

          <Box sx={{ m: "36px" }}></Box>
          <LoadingButton
            loading={isUpdating}
            fullWidth
            variant="contained"
            type="submit"
            disabled={
              values.plan_name === plan.plan_name &&
              values.max_contracts_per_month === plan.max_contracts_per_month &&
              values.notes === plan.notes
            }
          >
            Update
          </LoadingButton>

          <Box m="16px" />
          {plan.status === types.PlanStatus.PAUSED ? (
            <ResumePlanButton plan={plan} setPlan={setPlan} />
          ) : (
            <PausePlanButton plan={plan} setPlan={setPlan} />
          )}
        </Card>
      </Container>
    </FormikProvider>
  );
}

// https://mui.com/material-ui/react-progress/#linear-with-label
function LinearProgressWithLabel({
  value,
  ...rest
}: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          sx={{ height: "8px" }}
          value={Math.min(value, 100)}
          {...rest}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function ProgressBar({ title, value }: { title: string; value: number }) {
  return (
    <Box>
      <Typography variant="h3">{title}</Typography>
      <LinearProgressWithLabel value={value} />
    </Box>
  );
}

function PlanProgress({ plan }: { plan: types.Plan }) {
  const [progress, setProgress] = useState<types.PlanProgress>();

  useEffect(() => {
    fetchAPI(`/plan/${plan.id}/progress`)
      .then((res) => res.json())
      .then(setProgress);
  }, [plan.id]);

  if (!progress) {
    return <LinearProgress />;
  }

  const revenuePercent =
    (progress.revenue.completed / progress.revenue.target) * 100;
  const likesPercent = (progress.likes.completed / progress.likes.target) * 100;
  // Compute budget percent.
  const budgetPercent =
    Math.floor(Math.min(revenuePercent, likesPercent) / 10) * 10;

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: "600px",
        padding: "30px 26px",
      }}
    >
      <Typography variant="h2">Progress</Typography>
      <Box m="18px" />

      <ProgressBar title="Budget" value={budgetPercent} />

      <Box m="8px" />
      <ProgressBar title="Revenue" value={revenuePercent} />

      <Box m="8px" />
      <ProgressBar title="Likes" value={likesPercent} />
    </Card>
  );
}

export default function PlanSummary({ plan, setPlan }: PlanSummaryProps) {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* Progress is only a concept for combo. */}
      {!!plan.combo_type && plan.visible && (
        <>
          <PlanProgress plan={plan} />
          <Box m={{ xs: "18px", md: "30px" }} />
        </>
      )}

      {/* Show pending verification sign when the plan is yet visible. */}
      {!plan.visible && (
        <>
          <Card sx={{ width: "100%", maxWidth: "600px" }}>
            <CardContent>
              <Chip label="Pending Verification" />
              <Box m="4px" />
              <Typography>
                Our team will review and verify all the information. The plan
                will start automatically once verified. There is no action we
                need from you at the moment.
              </Typography>
            </CardContent>
          </Card>
          <Box m={{ xs: "16px", md: "24px" }} />
        </>
      )}

      <SummaryForm plan={plan} setPlan={setPlan} />
    </Box>
  );
}
