import * as types from "./types";
import * as PaymentUtils from "./PaymentUtils";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { fetchAPI } from "./authenticator_util";
import { useEffect, useState } from "react";

type PaymentMethodDropdownProps = {
  value?: string;
  onChange: (value: string) => any;
};

export default function PaymentMethodDropdown({
  value,
  onChange,
}: PaymentMethodDropdownProps) {
  const [paymentMethods, setPaymentMethods] = useState<types.PaymentMethod[]>(
    []
  );

  useEffect(() => {
    fetchAPI("/paypal/payment-methods")
      .then((response) => response.json())
      .then((methods) => {
        setPaymentMethods(methods);
        onChange(methods[0].id);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading = paymentMethods.length === 0;

  return (
    <FormControl fullWidth>
      <InputLabel id="payment-method-dropdown-label">Payment method</InputLabel>
      <Select
        labelId="payment-method-dropdown-label"
        id="payment-method-dropdown"
        size="small"
        value={loading ? "loading" : value}
        label="Payment method"
        onChange={loading ? undefined : (e) => onChange(e.target.value)}
      >
        {loading && (
          <MenuItem value="loading">
            <CircularProgress size={16} />
          </MenuItem>
        )}

        {!loading &&
          paymentMethods.map((method: types.PaymentMethod) => {
            const { brand, last4 } =
              PaymentUtils.getPaymentMethodDisplayInfo(method);
            return (
              <MenuItem key={method.id} value={method.id}>
                {`${brand.toUpperCase()} (•••• ${last4})`}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
