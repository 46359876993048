import { LinearProgress, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import { SyntheticEvent, useEffect, useState } from "react";
import PaymentStatus from "../PaymentStatus";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { LoadingButton } from "aagent-ui";
import { fetchAPI } from "../authenticator_util";
import { stripePromise } from "../stripeUtils";

function SetupForm({ setCheckStatus }: { setCheckStatus: any }) {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsSubmitting(true);
    setErrorMessage("");
    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
    });

    setIsSubmitting(false);
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setCheckStatus(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <LoadingButton
        type="submit"
        variant="contained"
        disabled={!stripe}
        loading={isSubmitting}
        sx={{ marginTop: "24px" }}
        fullWidth
      >
        Add
      </LoadingButton>

      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
}

export default function AddCard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const clientSecret = searchParams.get("clientSecret") || "";
  const redirectUrl = searchParams.get("redirectUrl") || "/";
  const [checkStatus, setCheckStatus] = useState<boolean>(false);

  useEffect(() => {
    if (!clientSecret) {
      fetchAPI("/bank/new-setup-intent-client-secret")
        .then((response) => response.json())
        .then((clientSecret) => setSearchParams({ clientSecret, redirectUrl }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSecret, redirectUrl]);

  if (!clientSecret) {
    return <LinearProgress />;
  }

  const options = {
    // passing the client secret obtained in step 2
    clientSecret,
  };

  return (
    <CardWithBottomNavLayout closeLinkTo={redirectUrl}>
      <Typography variant="h1" gutterBottom>
        Add new card
      </Typography>

      <Elements stripe={stripePromise} options={options}>
        <SetupForm setCheckStatus={setCheckStatus} />
        {checkStatus && (
          <PaymentStatus
            clientSecret={clientSecret}
            redirectUrl={redirectUrl}
          />
        )}
      </Elements>
    </CardWithBottomNavLayout>
  );
}
