import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { Contract } from "./types";
import ContractItem from "./ContractItem";

export default function PlanContracts({
  contracts,
}: {
  contracts: Contract[];
}) {
  return (
    <Container sx={{ maxWidth: { xs: "600px" } }}>
      <Box>
        {contracts.length === 0 && (
          <Card>
            <CardContent>
              <Typography>
                Currently you don't have any contracts under this plan yet.
              </Typography>
            </CardContent>
          </Card>
        )}

        {contracts.length > 0 &&
          contracts.map((contract) => (
            <ContractItem key={contract.id} contract={contract} />
          ))}
      </Box>
    </Container>
  );
}
