import Header from "./Header";
import * as types from "./types";
import * as constants from "./constants";
import {
  Alert,
  Box,
  Chip,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";
import { fetchAPI, patchAPI } from "./authenticator_util";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatUnixTimestamp } from "./time_util";
import { LoadingButton, SingleSelect, TextField, toast } from "aagent-ui";
import { getLabel, getLatestStatus } from "./statusUtils";
import ImageCarouselWithModal from "./ImageCarouselWithModal";
import BottomNavigationLayout from "./BottomNavigationLayout";

// interface ContractDetailsProp extends Authenticated {
interface ContractDetailsProp {
  contract: types.Contract;
  setContract: (newContract: types.Contract) => any;
}

function ActionPanel({ contract, setContract }: ContractDetailsProp) {
  const status = getLatestStatus(contract.statuses);
  const [selectedAction, setSelectedAction] = useState<types.StatusType>();
  const [reason, setReason] = useState<string>("");
  const [requestAction, setRequestAction] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleAction = () => {
    setLoading(true);

    if (selectedAction === types.StatusType.APPROVED) {
      patchAPI(`/contract/${contract.id}/approve`)
        .then((response) => response.json())
        .then((newContract) => setContract(newContract))
        .then(() => toast.success("Contract approved"))
        .catch((err) => toast.error(String(err)))
        .finally(() => setLoading(false));
    } else if (selectedAction === types.StatusType.DECLINED) {
      patchAPI(`/contract/${contract.id}/decline`, { reason })
        .then((response) => response.json())
        .then((newContract) => setContract(newContract))
        .then(() => toast.success("Contract declined"))
        .catch((err) => toast.error(String(err)))
        .finally(() => setLoading(false));
    } else if (selectedAction === types.StatusType.REQUEST_INFO) {
      patchAPI(`/contract/${contract.id}/request-info`, {
        reason,
        request_action: requestAction,
      })
        .then(() => toast.success("Request is sent to the influencer."))
        .catch((err) => toast.error(String(err)))
        .finally(() => setLoading(false));
    }
  };

  const actionOptions = [
    { name: types.StatusType.APPROVED, label: "Approve" },
    { name: types.StatusType.DECLINED, label: "Decline" },
    { name: types.StatusType.REQUEST_INFO, label: "Request Info" },
  ];

  const incompleteDeclineForm =
    selectedAction === types.StatusType.DECLINED && reason.length === 0;
  const incompleteRequestInfoForm =
    selectedAction === types.StatusType.REQUEST_INFO &&
    (reason.length === 0 || requestAction.length === 0);

  return (
    <Box>
      {status !== types.StatusType.WAIT_APPROVAL && (
        <Box>
          <Typography sx={{ display: "inline-block", mr: "10px" }}>
            Status:{" "}
          </Typography>
          <Chip label={getLabel(status)} />
        </Box>
      )}

      {(status === types.StatusType.WAIT_APPROVAL ||
        status === types.StatusType.REQUEST_INFO) && (
        <Box>
          <SingleSelect
            label="Choose an action"
            options={actionOptions}
            selected={selectedAction}
            onSelect={(action) => setSelectedAction(action as types.StatusType)}
          />

          {(selectedAction === types.StatusType.DECLINED ||
            selectedAction === types.StatusType.REQUEST_INFO) && (
            <>
              <Box m="20px" />
              <TextField
                label="Reason"
                fullWidth
                multiline
                minRows={3}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </>
          )}

          {selectedAction === types.StatusType.REQUEST_INFO && (
            <>
              <Box m="20px" />
              <TextField
                label="Request Action"
                fullWidth
                multiline
                minRows={3}
                value={requestAction}
                onChange={(e) => setRequestAction(e.target.value)}
              />
            </>
          )}

          {!!selectedAction && (
            <>
              <Box m="20px" />
              <LoadingButton
                disabled={incompleteDeclineForm || incompleteRequestInfoForm}
                loading={loading}
                onClick={handleAction}
                fullWidth
              >
                Confirm
              </LoadingButton>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

function ReceiptSection({ contract }: { contract: types.Contract }) {
  const images = contract.receipts.flatMap((receipt) => receipt.image_s3_path);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6">Receipt</Typography>
      <Typography>Subtotal: ${contract.subtotal || 0}</Typography>
      <ImageCarouselWithModal isS3Image imageArray={images} />
    </Box>
  );
}

function SocialMediaSection({ contract }: { contract: types.Contract }) {
  if (contract.social_medias.length === 0) {
    return null;
  }

  const media = contract.social_medias[0];
  const likesRequired =
    constants.MEDIA_TIER_LIKES[media.platform][contract.tier];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6">Social Media</Typography>
      <Typography>{media.platform}</Typography>
      <Typography>
        Likes: {media.likes || 0} (required {likesRequired})
      </Typography>
      <ImageCarouselWithModal
        isS3Image
        imageArray={contract.social_medias.flatMap(
          (media) => media.image_s3_paths
        )}
      />
    </Box>
  );
}

function ContractDetails({ contract, setContract }: ContractDetailsProp) {
  const status = getLatestStatus(contract.statuses);

  return (
    <Box>
      <ActionPanel contract={contract} setContract={setContract} />
      <Box m="30px" />

      {/* Disput status alarm box */}
      {status === types.StatusType.DISPUTE && (
        <>
          <Alert severity="info">Dispute is in progress</Alert>
          <Box m="30px" />
        </>
      )}

      {/* Metadata */}
      <Typography>Location: {contract.post.store.business_name}</Typography>
      <Typography gutterBottom>
        Last updated: {formatUnixTimestamp(contract.last_updated)}
      </Typography>

      <Box m="30px" />
      {!contract.post.skip_receipt && <ReceiptSection contract={contract} />}

      <Box m="30px" />
      <SocialMediaSection contract={contract} />
    </Box>
  );
}

export default function ContractDetailsPage() {
  const { contractId } = useParams();
  const [contract, setContract] = useState<types.Contract>();
  const loading = !contract;

  useEffect(() => {
    fetchAPI(`/contract/${contractId}`)
      .then((response) => response.json())
      .then(setContract);
  }, [contractId]);

  return (
    <BottomNavigationLayout>
      <Header />
      <Container sx={{ maxWidth: { xs: "600px" }, paddingBottom: "30px" }}>
        {loading && <LinearProgress />}
        {!loading && (
          <ContractDetails contract={contract} setContract={setContract} />
        )}
      </Container>
    </BottomNavigationLayout>
  );
}
