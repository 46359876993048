import { Box, Container, Link, Typography } from "@mui/material";
import { Card, LoadingButton, TextField, toast } from "aagent-ui";
import { useState } from "react";
import { Link as RouteLink } from "react-router-dom";
import { fetchAPI } from "../authenticator_util";

export default function SignUpBySales() {
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<number>(9998887777);
  const [loading, setLoading] = useState<boolean>(false);

  const saleCreateAccount = () => {
    if (!email.trim()) return;
    if (!phoneNumber) return;
    setLoading(true);

    fetchAPI("/merchant/sign-up-by-sales", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "text/plain",
      },
      body: JSON.stringify({ email: email.trim(), phone_number: phoneNumber }),
    })
      .then(() => {
        toast.success(`Created user account: ${email.trim()}`);
        setEmail("");
      })
      .catch((err) => toast.error(String(err)))
      .finally(() => setLoading(false));
  };

  return (
    <Box pt="30px">
      <Container>
        <Typography variant="h1">Sales Portal</Typography>
        <Box m="50px" />

        {/* Sale create merchant account */}
        <Card p="40px 24px">
          <Typography variant="h2">Create merchant account</Typography>
          <Box m="20px" />
          <Typography>Temporary password will be: AAgentSales@123</Typography>
          <Box m="20px" />

          <TextField
            name="email"
            label="email"
            value={email}
            fullWidth
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <Box m="40px" />

          <Typography>
            Please enter 10-digit number. For example, if the customer's phone
            number is +1 (999) 888-7777. Then please just enter "9998887777" in
            the field below.
          </Typography>
          <Box m="20px" />
          <TextField
            name="phoneNumber"
            label="Phone Number"
            value={phoneNumber}
            fullWidth
            onChange={(e: any) =>
              setPhoneNumber(Number.parseInt(e.target.value))
            }
          />

          <Box m="40px" />
          <LoadingButton
            fullWidth
            loading={loading}
            onClick={saleCreateAccount}
          >
            Create
          </LoadingButton>
        </Card>

        {/* Convenient links */}
        <Box m="40px" />
        <Card p="40px 24px">
          <Typography variant="h2">Convenient Links</Typography>
          <ul>
            <li>
              <Link component={RouteLink} to="/">
                Home (sign up page)
              </Link>
            </li>
          </ul>
        </Card>
      </Container>
    </Box>
  );
}
