import { ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { withAuthenticator } from "./authenticator_util";
import { ScrollToTop, theme } from "aagent-ui";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import AddPlan from "./pages/AddPlan";
import AllContracts from "./pages/AllContracts";
import ContractDetailsPage from "./ContractDetailsPage";
import CreatePlanWithType from "./pages/CreatePlanWithType";
import MerchantInfo from "./pages/MerchantInfo";
import MoneyTransfer from "./pages/MoneyTransfer";
import Plan from "./pages/Plan";
import Plans from "./pages/Plans";
import Settings from "./pages/Settings";
import BankInfo from "./pages/BankInfoPaypal";
import AddCard from "./pages/AddCard";
import HelpPage from "./pages/HelpPage";
import StoresInfo from "./pages/StoresInfo";
import AddStore from "./pages/AddStore";
import EditStore from "./pages/EditStore";
import SignUpBySales from "./pages/SignUpBySales";
import ResetPassword from "./pages/ResetPassword";
import "./index.css";
import "aagent-ui/dist/index.css";
import "./stripeUtils";

const MERCHANT_USER_TYPE = "MERCHANT";
function AuthenticatedRoutes() {
  const authenticatedRoutes = [
    { path: "/", Component: Plans },
    { path: "/plans", Component: Plans },
    { path: "/plan/:planId", Component: Plan },
    { path: "/moneytransfer", Component: MoneyTransfer },

    { path: "/add-plan", Component: AddPlan },
    { path: "/add-plan/:planType", Component: CreatePlanWithType },

    { path: "/contracts", Component: AllContracts },
    { path: "/contract/:contractId", Component: ContractDetailsPage },

    { path: "/settings", Component: Settings },
    { path: "/settings/info", Component: MerchantInfo },
    { path: "/settings/stores", Component: StoresInfo },
    { path: "/settings/stores/add", Component: AddStore },
    { path: "/settings/bank-info", Component: BankInfo },
    { path: "/settings/add-card", Component: AddCard },
    { path: "/settings/help", Component: HelpPage },
    { path: "/settings/store/:storeId", Component: EditStore },
  ];

  return (
    <Routes>
      <Route path="/sign-up-by-sales" element={<SignUpBySales />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      {authenticatedRoutes.map(({ path, Component }, index) => {
        const ComponentWithAuth = withAuthenticator(
          (props: any) => <Component {...props} />,
          MERCHANT_USER_TYPE
        );
        return (
          <Route key={index} path={path} element={<ComponentWithAuth />} />
        );
      })}
    </Routes>
  );
}

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />
          <AuthenticatedRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
