import {
  Authenticator,
  CheckboxField,
  useAuthenticator,
  withAuthenticator as withAuthenticatorOrigin,
} from "@aws-amplify/ui-react";
import { Link } from "@mui/material";
import { AMPLIFY_CONFIG, API_DOMAIN } from "./constants";
import { Amplify, Auth } from "aws-amplify";
import { User } from "./types";
import RequireInfo from "./RequireInfo";
import AuthenticationHeader from "./AuthenticationHeader";
import "@aws-amplify/ui-react/styles.css";
Amplify.configure(AMPLIFY_CONFIG);

export interface Authenticated {
  signOut: () => any;
  user: User;
  updateAttributes: (attributes: any) => void;
}

export function withAuthenticator(
  component: any,
  userType: string,
  initialAuthState?: "signIn" | "signUp" | "resetPassword"
) {
  // Instruction to add "user_type" field in sign up:
  // https://ui.docs.amplify.aws/components/authenticator?platform=react#sign-up-fields
  const options = {
    className: "gradient-background",
    components: {
      SignUp: {
        FormFields() {
          const { validationErrors } = useAuthenticator();
          return (
            <>
              {/* Re-use default `Authenticator.SignUp.FormFields` */}
              <Authenticator.SignUp.FormFields />
              <CheckboxField
                errorMessage={validationErrors.acknowledgement as string}
                hasError={!!validationErrors.acknowledgement}
                name="acknowledgement"
                value="yes"
                label={
                  <span>
                    I agree with the{" "}
                    <Link href="https://aagent.co/terms" target="_blank">
                      Terms & Conditions
                    </Link>
                  </span>
                }
              />
            </>
          );
        },
      },
      Header: AuthenticationHeader,
    },
    services: {
      async handleSignUp(formData: any) {
        const { attributes, ...rest } = formData;
        return Auth.signUp({
          ...rest,
          attributes: {
            ...attributes,
            "custom:user_type": userType,
            "custom:profile_complete": "false",
            "custom:created_by_sales": "false",
          },
        });
      },
      async validateCustomSignUp(formData: any) {
        if (!formData.acknowledgement) {
          return {
            acknowledgement: "You must agree to the Terms & Conditions",
          };
        }
      },
      async handleSignIn(formData: any) {
        const { username, password } = formData;
        return Auth.signIn({ username, password }).then((result) => {
          // Don't block by this function call. Allow the user to log in even if this request fails
          fetchAPI("/update-last-login-at", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "text/plain",
            },
          });
          return result;
        });
      },
    },
    initialState: initialAuthState,
  };

  return withAuthenticatorOrigin(RequireInfo(component), options);
}

async function getUserIdTokenOrUndefined() {
  try {
    // You may have saved off the JWT somewhere when the user logged in.
    // If not, get the token from aws-amplify:
    const user = await Auth.currentAuthenticatedUser();

    // See here for difference between id token and access token:
    // https://auth0.com/blog/id-token-access-token-what-is-the-difference/
    // const accessToken = user.signInUserSession.accessToken.jwtToken;
    const idToken = user.signInUserSession.idToken.jwtToken;
    return idToken;
  } catch {
    return undefined;
  }
}

export async function fetchAPI(path: string, init: any = undefined) {
  const url = API_DOMAIN + path;

  const idToken = await getUserIdTokenOrUndefined();

  let request = init || {};
  request.headers = {
    ...(request.headers || {}),
    // Use JWT when user is present, otherwise sets an empty authorization header.
    Authorization: idToken ? `Bearer ${idToken}` : undefined,
  };

  return fetch(url, request).then(async (response) => {
    if (!response.ok) {
      const text = await response.text();
      throw Error(`${response.statusText}: ${text}`);
    }
    return response;
  });
}

export async function patchAPI(path: string, data: any = undefined) {
  return fetchAPI(path, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: !!data ? JSON.stringify(data) : undefined,
  });
}

export async function postAPI(path: string, data: any = undefined) {
  return fetchAPI(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: !!data ? JSON.stringify(data) : undefined,
  });
}

export async function getCurrentUser() {
  return Auth.currentAuthenticatedUser();
}
