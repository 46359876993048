import * as types from "./types";

export function getLatestStatus(statuses: types.Status[]): types.StatusType {
  if (!statuses || statuses.length === 0) {
    return types.StatusType.WORK_IN_PROGRESS;
  }

  return statuses[statuses.length - 1].status_type;
}

export function isLatestCompleted(statuses: types.Status[]): boolean {
  return getLatestStatus(statuses) !== types.StatusType.WORK_IN_PROGRESS;
}

export function getLabel(status: types.StatusType) {
  switch (status) {
    case types.StatusType.APPROVED:
      return "Approved";
    case types.StatusType.DECLINED:
      return "Declined";
    case types.StatusType.REQUEST_INFO:
      return "Info Requested";
    case types.StatusType.DISPUTE:
      return "Dispute";
    case types.StatusType.GIVE_UP:
      return "Give Up";
    case types.StatusType.WAIT_APPROVAL:
      return "Pending Approval";
    case types.StatusType.WORK_IN_PROGRESS:
      return "In Progress";
    default:
      return "Unknown";
  }
}
