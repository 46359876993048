import { Box } from "@mui/material";
import { useEffect } from "react";
import { withAuthenticator } from "../authenticator_util";
import { useNavigateRef } from "../commonUtils";

function ResetPassword() {
  const navRef = useNavigateRef();

  useEffect(() => {
    setTimeout(() => navRef.current("/"), 1000);
  }, [navRef]);

  return <Box>Already logged in, redirecting to home ...</Box>;
}

export default withAuthenticator(ResetPassword, "MERCHANT", "resetPassword");
