import { GUEST_V2_TIER_TO_REIMBURSEMENT } from "./constants";
import * as types from "./types";
import * as statusUtils from "./statusUtils";

// Impact tier to charge Ratio and Bonus.
const IMPACT_TIER_TO_CRB = {
  [types.PlanTier.ENTRY]: [0.35, 5.0],
  [types.PlanTier.STANDARD]: [0.45, 25.0],
  [types.PlanTier.PREMIUM]: [0.55, 45.0],
  [types.PlanTier.STAR]: [1.2, 75.0],
  [types.PlanTier.FREE]: [1.2, 10.0],
  [types.PlanTier.FREEP]: [1.4, 35.0],
};
// Impact tier to Transfer Ratio and Bonus.
const IMPACT_TIER_TO_TRB = {
  [types.PlanTier.ENTRY]: [0.3, 5.0],
  [types.PlanTier.STANDARD]: [0.35, 15.0],
  [types.PlanTier.PREMIUM]: [0.4, 30.0],
  [types.PlanTier.STAR]: [1.0, 50.0],
  [types.PlanTier.FREE]: [1.0, 0.0],
  [types.PlanTier.FREEP]: [1.0, 20.0],
};

// Fame tier to Charge Bonus.
const FAME_TIER_TO_CB = {
  [types.PlanTier.ENTRY]: 20.0,
  [types.PlanTier.STANDARD]: 40.0,
  [types.PlanTier.PREMIUM]: 70.0,
  [types.PlanTier.STAR]: 105.0,
  [types.PlanTier.FREE]: 0.0,
  [types.PlanTier.FREEP]: 0.0,
};

function computeMaxReimbursement(contract: types.Contract): number {
  const { post, tier } = contract;
  const reimbursement_for_influencer = Number(post.max_reimbursement_per_bill);

  if (post.plan_type === types.PlanType.GUEST_V2) {
    // Transfer is full subtotal. We charge 5% extra.
    return 1.05 * GUEST_V2_TIER_TO_REIMBURSEMENT[tier];
  } else if (post.plan_type === types.PlanType.IMPACT) {
    const charge_ratio = IMPACT_TIER_TO_CRB[tier][0];
    const transfer_ratio = IMPACT_TIER_TO_TRB[tier][0];
    return (reimbursement_for_influencer / transfer_ratio) * charge_ratio;
  }

  // For Fame, this should be zero.
  return reimbursement_for_influencer;
}

function computeReimbursementNoCap(contract: types.Contract): number {
  const { post, subtotal, tier, success } = contract;
  if (!subtotal) {
    return 0.0;
  }

  // Handle failure case
  if (success === false) {
    if (post.plan_type === types.PlanType.IMPACT) {
      return subtotal * 0.2;
    } else if (post.plan_type === types.PlanType.GUEST_V2) {
      return subtotal * 0.3;
    }
    return 0.0;
  }

  // Handle success case
  switch (post.plan_type) {
    case types.PlanType.GUEST:
      return subtotal * 0.3;
    case types.PlanType.GUEST_V2:
      return subtotal;
    case types.PlanType.IMPACT:
      const ratio = IMPACT_TIER_TO_CRB[tier][0];
      return subtotal * ratio;
    default:
      return 0.0;
  }
}

function computeBonus(contract: types.Contract): number {
  const { post, subtotal, tier, success } = contract;
  if (!subtotal) {
    return 0.0;
  }

  // Handle failure case
  if (!success) {
    return 0.0;
  }

  // Handle success case
  switch (post.plan_type) {
    case types.PlanType.IMPACT:
      const bonus = IMPACT_TIER_TO_CRB[tier][1];
      return bonus;
    case types.PlanType.FAME:
      return FAME_TIER_TO_CB[tier];
    default:
      return 0.0;
  }
}

function computeCharge(contract: types.Contract): number {
  const { subtotal, post } = contract;
  const reimbursement = Math.min(
    computeReimbursementNoCap(contract),
    computeMaxReimbursement(contract)
  );
  const bonus = computeBonus(contract);
  let charge = reimbursement + bonus;

  // Guard: charge at most 70% of subtotal.
  if (!!subtotal && post.plan_type !== types.PlanType.GUEST_V2) {
    charge = Math.min(charge, subtotal * 0.7);
  }
  return charge;
}

// https://stackoverflow.com/a/16233919
// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function formatChargeAmount(contract: types.Contract): String {
  if (!contract.subtotal) {
    return "Pending";
  }

  const charge = computeCharge(contract);
  return formatter.format(charge);
}

export function getLatestStatus(contract: types.Contract): types.StatusType {
  return statusUtils.getLatestStatus(contract.statuses);
}

// Only used to compute priority
function getStatusScore(status: types.StatusType) {
  switch (status) {
    case types.StatusType.WAIT_APPROVAL:
      return 9;
    case types.StatusType.WORK_IN_PROGRESS:
      return 8;
    case types.StatusType.REQUEST_INFO:
      return 7;
    case types.StatusType.APPROVED:
      return 6;
    case types.StatusType.DECLINED:
      return 5;
    case types.StatusType.DISPUTE:
      return 4;
    case types.StatusType.GIVE_UP:
      return 1;
  }
}

// First sort by status priority.
// Then sort by created timestamp.
export function sortByPriority(contracts: types.Contract[]): types.Contract[] {
  const getScore = (contract: types.Contract) => {
    let score = getStatusScore(getLatestStatus(contract));
    score *= 1e7;
    // Timestamp is a 13 digit number. Example: 1662179559057
    const timestamp = new Date(contract.created_timestamp).getTime();
    score += timestamp / 1e6;
    return score;
  };

  return [...contracts].sort((c1, c2) => getScore(c2) - getScore(c1));
}

export function readyForDetails(contract: types.Contract): boolean {
  const status = getLatestStatus(contract);
  switch (status) {
    case types.StatusType.WORK_IN_PROGRESS:
    case types.StatusType.GIVE_UP:
      return false;
    default:
      return true;
  }
}
