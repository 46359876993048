import { LinearProgress, Typography } from "@mui/material";
import { toast } from "aagent-ui";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import StoreForm from "../StormForm";
import * as types from "../types";

export default function EditStore({ user }: { user: types.User }) {
  const { storeId } = useParams() as { storeId: string };
  const [merchant, setMerchant] = useState<types.MerchantInfo>();
  const [store, setStore] = useState<types.Store>();
  const navigate = useNavigate();

  // Load merchant info
  useEffect(() => {
    fetchAPI("/merchant/info")
      .then((res) => res.json())
      .then(setMerchant)
      .catch((err) => toast.error(String(err)));

    fetchAPI(`/merchant/store/${storeId}`)
      .then((res) => res.json())
      .then(setStore)
      .catch((err) => toast.error(String(err)));
  }, [storeId]);
  const loading = !merchant || !store;

  const handleSuccess = () => {
    setTimeout(() => {
      navigate("/settings/stores");
    }, 2000);
  };

  return (
    <CardWithBottomNavLayout>
      <Typography variant="overline">Edit</Typography>
      <Typography variant="h1" sx={{ marginBottom: "32px" }}>
        Location
      </Typography>

      {loading && <LinearProgress />}
      {!loading && (
        <StoreForm
          user={user}
          merchant={merchant}
          store={store}
          onSuccess={handleSuccess}
        />
      )}
    </CardWithBottomNavLayout>
  );
}
