// http://jsfiddle.net/vN65r/
import { Box, SxProps } from "@mui/material";
import { ReactNode } from "react";
import BottomNavigation from "./BottomNavigation";

type BottomNavigationLayoutProps = {
  sx?: SxProps;
  children?: ReactNode;
};

export default function BottomNavigationLayout({
  sx,
  children,
}: BottomNavigationLayoutProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        justifyContent: "space-between",

        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        // https://css-tricks.com/a-complete-guide-to-custom-properties/#aa-supports
        height: "calc(var(--vh, 1vh) * 100)",
        "@supports not (--custom: property)": {
          height: "100vh",
        },
      }}
    >
      <Box sx={{ flex: "0 1 auto", overflow: "auto", ...(sx || {}) }}>
        {children}
      </Box>
      <BottomNavigation sx={{ flex: "0 0 auto" }} />
    </Box>
  );
}
