import { Box, Chip } from "@mui/material";
import { TextField } from "aagent-ui";
import { useState } from "react";

type TagsInputProps = {
  tags: string[];
  onChange: (tags: string[]) => any;
};

export default function TagsInput({ tags, onChange }: TagsInputProps) {
  const [tag, setTag] = useState<string>("");
  const updateText = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setTag(e.target.value);
  };

  const handleAddTag = () => {
    let newTags = new Set([...tags, tag.trim()]);
    onChange(Array.from(newTags));
    setTag("");
  };

  const handleDeleteTag = (tag: string) => {
    let newTags = new Set(tags);
    newTags.delete(tag);
    onChange(Array.from(newTags));
  };

  return (
    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      <Box sx={{ display: "flex" }}>
        {tags.map((tag, index) => (
          <Chip key={index} label={tag} onDelete={() => handleDeleteTag(tag)} />
        ))}
      </Box>
      <TextField
        id="tags-textfield"
        label="Tags (hit enter to add)"
        variant="standard"
        onChange={updateText}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleAddTag();
          }
        }}
        value={tag}
      />
    </Box>
  );
}
