import { Box, Typography } from "@mui/material";
import { FormikErrors, FormikTouched } from "formik";
import { TextField, toast } from "aagent-ui";
import { v4 as uuidv4 } from "uuid";
import { Storage } from "aws-amplify";
import * as types from "./types";
import ImageUploader from "./ImageUploader";
import Resizer from "react-image-file-resizer";
import TagsInput from "./TagsInput";

interface StoreFormBodyProps {
  values: types.Store;
  errors: FormikErrors<types.Store>;
  touched: FormikTouched<types.Store>;
  prefix: string;
  handleChange: any;
  setFieldValue: any;
}

interface SelectOption {
  name: any;
  label: string;
}

interface SelectWidgetProps {
  options: SelectOption[];
  value: any;
  onSelect: (option: SelectOption) => any;
}

function SelectWidget({ options, value, onSelect }: SelectWidgetProps) {
  const width = `${100 / options.length}%`;

  const renderOption = (option: SelectOption, i: number) => {
    let borderRadius = undefined;
    if (i === 0) {
      borderRadius = "5px 0 0 5px";
    } else if (i + 1 === options.length) {
      borderRadius = "0 5px 5px 0";
    }

    const color = option.name === value ? "#FFF" : "primary.dark";
    const backgroundColor = option.name === value ? "primary.dark" : "#FFF";

    return (
      <Box
        key={i}
        sx={{
          width,
          borderRadius,
          backgroundColor,
          border: "1px solid",
          borderColor: "primary.dark",
          borderSizing: "border-box",
          padding: "8px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => onSelect(option)}
      >
        <Typography variant="h4" sx={{ color, fontSize: "11px" }}>
          {option.label}
        </Typography>
      </Box>
    );
  };

  return <Box sx={{ display: "flex" }}>{options.map(renderOption)}</Box>;
}

export default function StoreFormBody({
  errors,
  touched,
  values,
  prefix,
  handleChange,
  setFieldValue,
}: StoreFormBodyProps) {
  const touchedAddress = touched.address || {};
  const errorsAddress = errors.address || {};
  const businessTypeOptions = Object.values(types.BusinessType).map(
    (businessType) => ({
      name: businessType,
      label: businessType.toLowerCase(),
    })
  );

  const priceRangeOptions = [
    { name: 1, label: "< $10" },
    { name: 2, label: "$10 - $30" },
    { name: 3, label: "$30 - $60" },
    { name: 4, label: "> $60" },
  ];

  const resizeFile = (file: any, maxHeight: number, maxWidth: number) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const updateStoreImages = (files: File[]) => {
    if (files.length === 0) {
      return;
    }
    const filenames = files.map(
      (file) => `og/store/${values.id}/${uuidv4()}-${file.name}`
    );
    const results = files.map((file, index) => {
      const originalFileName = filenames[index];
      return Object.entries(types.ImgFileSize).map(async ([k, v], i) => {
        const fileName =
          k === "og" ? originalFileName : originalFileName.replace("og", k);
        const img =
          k === "og" ? file : await resizeFile(file, v.maxHeight, v.maxWidth);
        return Storage.put(fileName, img, {
          level: "public",
          progressCallback: (progress) =>
            console.log(
              `Uploaded file sizes: ${
                (100 * progress.loaded) / progress.total
              }%`
            ),
        });
      });
    });

    return Promise.all(results)
      .then((results) => {
        setFieldValue(`${prefix}image_s3_paths`, filenames);
        return toast.success(`Uploaded ${results.length} image(s)`);
      })
      .catch((err) => toast.error(String(err)));
  };

  return (
    <Box>
      {/* Business type */}
      <Typography variant="subtitle1" gutterBottom>
        Business Type
      </Typography>
      <SelectWidget
        options={businessTypeOptions}
        value={values.business_type}
        onSelect={(option) =>
          setFieldValue(`${prefix}business_type`, option.name)
        }
      />
      {values.business_type === types.BusinessType.OTHER && (
        <TextField
          name={`${prefix}custom_business_type`}
          label="Custom business type"
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          value={values.custom_business_type}
          onChange={handleChange}
          error={
            touched.custom_business_type && Boolean(errors.custom_business_type)
          }
          helperText={
            touched.custom_business_type && errors.custom_business_type
          }
        />
      )}
      <Box m="12px" />

      {/* Price range per person */}
      <Typography variant="subtitle1" gutterBottom>
        Price range per person
      </Typography>
      <SelectWidget
        options={priceRangeOptions}
        value={values.price_range_per_person}
        onSelect={(option) =>
          setFieldValue(`${prefix}price_range_per_person`, option.name)
        }
      />
      <Box m="12px" />

      {/* Business name */}
      <TextField
        name={`${prefix}business_name`}
        label="Business Name"
        variant="outlined"
        fullWidth
        size="small"
        margin="normal"
        value={values.business_name}
        onChange={handleChange}
        error={touched.business_name && Boolean(errors.business_name)}
        helperText={touched.business_name && errors.business_name}
      />

      <TextField
        name={`${prefix}address.address_line1`}
        label="Address"
        variant="outlined"
        fullWidth
        size="small"
        margin="normal"
        value={values.address.address_line1}
        onChange={handleChange}
        error={
          touchedAddress.address_line1 && Boolean(errorsAddress.address_line1)
        }
        helperText={touchedAddress.address_line1 && errorsAddress.address_line1}
      />

      <TextField
        name={`${prefix}address.city`}
        label="City"
        variant="outlined"
        fullWidth
        size="small"
        margin="normal"
        value={values.address.city}
        onChange={handleChange}
        error={touchedAddress.city && Boolean(errorsAddress.city)}
        helperText={touchedAddress.city && errorsAddress.city}
      />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          name={`${prefix}address.state`}
          label="State"
          variant="outlined"
          sx={{ width: "32%" }}
          size="small"
          margin="normal"
          value={values.address.state}
          onChange={handleChange}
          error={touchedAddress.state && Boolean(errorsAddress.state)}
          helperText={touchedAddress.state && errorsAddress.state}
        />

        <TextField
          name={`${prefix}address.zipcode`}
          label="Zipcode"
          variant="outlined"
          sx={{ width: "32%" }}
          size="small"
          margin="normal"
          value={values.address.zipcode}
          onChange={handleChange}
          error={touchedAddress.zipcode && Boolean(errorsAddress.zipcode)}
          helperText={touchedAddress.zipcode && errorsAddress.zipcode}
        />

        <TextField
          name={`${prefix}address.neighborhood`}
          label="Neighborhood (optional)"
          variant="outlined"
          sx={{ width: "32%" }}
          size="small"
          margin="normal"
          value={values.address.neighborhood}
          onChange={handleChange}
          error={
            touchedAddress.neighborhood && Boolean(errorsAddress.neighborhood)
          }
          helperText={touchedAddress.neighborhood && errorsAddress.neighborhood}
        />
      </Box>
      <Box m="2px" />

      {/* Add tags */}
      <TagsInput
        tags={values.tags || []}
        onChange={(tags) => setFieldValue(`${prefix}tags`, tags)}
      />

      <Box m="24px" />
      <ImageUploader
        initialImageS3Paths={values.image_s3_paths}
        onChange={updateStoreImages}
      />
    </Box>
  );
}
