import { Box, Dialog } from "@mui/material";
import * as types from "./types";

function ImpactContent() {
  return (
    <ul>
      <li>Entry: 35% bill + $5 bonus</li>
      <li>Standard: 45% + $20 bonus</li>
      <li>Premium: 55% + $45 bonus</li>
    </ul>
  );
}

function FameContent() {
  return (
    <ul>
      <li>Entry: $20 bonus</li>
      <li>Standard: $40 bonus</li>
      <li>Premium: $70 bonus</li>
    </ul>
  );
}

export default function CreatePlanHelpDialog({
  open,
  onClose,
  planType,
}: {
  open: boolean;
  onClose: () => any;
  planType: types.PlanType;
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paperScrollPaper": {
          width: "80%",
          padding: "24px 24px",
          borderRadius: "12px",
          backgroundColor: "#fbfbff",
        },
      }}
    >
      <Box>
        {planType === types.PlanType.IMPACT && <ImpactContent />}
        {planType === types.PlanType.FAME && <FameContent />}
      </Box>
    </Dialog>
  );
}
