import { LinearProgress, Typography } from "@mui/material";
import { toast } from "aagent-ui";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import { useNavigate } from "react-router-dom";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import StoreForm from "../StormForm";
import * as types from "../types";

export default function AddStore({ user }: { user: types.User }) {
  const [merchant, setMerchant] = useState<types.MerchantInfo>();
  const navigate = useNavigate();

  // Load merchant info
  useEffect(() => {
    fetchAPI("/merchant/info")
      .then((res) => res.json())
      .then(setMerchant)
      .catch((err) => toast.error(String(err)));
  }, []);

  const handleSuccess = () => {
    setTimeout(() => {
      navigate("/settings/stores");
    }, 2000);
  };

  return (
    <CardWithBottomNavLayout>
      <Typography variant="overline">Add</Typography>
      <Typography variant="h1" sx={{ marginBottom: "32px" }}>
        Location
      </Typography>

      {!merchant && <LinearProgress />}
      {!!merchant && (
        <StoreForm user={user} merchant={merchant} onSuccess={handleSuccess} />
      )}
    </CardWithBottomNavLayout>
  );
}
