import { ReactNode } from "react";
import { Box, SxProps } from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

export default function IconCard({
  icon,
  children,
  onClick,
  sx,
}: {
  icon: ReactNode;
  children: ReactNode;
  onClick?: any;
  sx?: SxProps;
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        background: "#FFFFFF",
        border: "1px solid rgba(238, 234, 248, 0.75)",
        boxSizing: "border-box",
        borderRadius: "11px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        padding: "14px",
        ...(sx || {}),
      }}
    >
      {icon}
      <Box sx={{ flex: 1, padding: "0 14px" }}>{children}</Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ArrowForwardIosOutlinedIcon
          sx={{
            height: "18px",
            color: "primary.light",
          }}
        />
      </Box>
    </Box>
  );
}
