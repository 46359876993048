import { useEffect, useRef } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

// This is a workaround for excessive re-renders on route change, and
// warning about navigate as a dependency in useEffect.
// A ref object never changes, and never triggers re-render.
// https://github.com/remix-run/react-router/discussions/8465#discussioncomment-2141437
export const useNavigateRef = () => {
  const navigate = useNavigate();
  const navRef = useRef<NavigateFunction>(navigate);

  useEffect(() => {
    navRef.current = navigate;
  }, [navigate]);

  return navRef;
};
