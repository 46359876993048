import { Box, Typography } from "@mui/material";
import { LoadingButton, TextField, toast } from "aagent-ui";
import { useFormik } from "formik";
import { useState } from "react";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import * as yup from "yup";
import { fetchAPI } from "../authenticator_util";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  content: yup
    .string()
    .required("Please enter some content so we can better assist you")
    .min(1, "Please enter some content so we can better assist you"),
});

export default function HelpPage() {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const initialValues = {
    content: "",
  };
  const navigate = useNavigate();

  const onSubmit = async (rawValues: typeof initialValues) => {
    setIsSubmitting(true);
    const values = {
      href: window.location.href,
      ...rawValues,
    };

    fetchAPI("/help", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(values),
    })
      .then(() => {
        toast.success("Question submitted. We will follow up soon!");
        setIsSubmitting(false);
      })
      .then(() => navigate("/settings"))
      .catch(toast.error);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <CardWithBottomNavLayout>
      <Typography variant="overline">Ask</Typography>
      <Typography variant="h1" sx={{ marginBottom: "32px" }}>
        Help
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Box>
          <TextField
            name="content"
            label="content"
            value={formik.values.content}
            onChange={formik.handleChange}
            fullWidth
            multiline
            minRows={4}
            error={!!formik.touched.content && !!formik.errors.content}
            helperText={formik.touched.content && formik.errors.content}
          />
        </Box>
        <Box m="40px" />
        <LoadingButton type="submit" fullWidth loading={isSubmitting}>
          Submit
        </LoadingButton>
      </form>
    </CardWithBottomNavLayout>
  );
}
