import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const COMBO_BUDGET_CHOICES = [500, 1000, 1500, 2000];

type ComboBudgetDropdownProps = {
  value: number;
  onChange: (val: number) => any;
};

export default function ComboBudgetDropdown({
  value,
  onChange,
}: ComboBudgetDropdownProps) {
  return (
    <FormControl fullWidth>
      <InputLabel id="combo-buget-dropdown-label">Monthly Budget</InputLabel>
      <Select
        labelId="combo-buget-dropdown-label"
        id="combo-buget-dropdown"
        size="small"
        value={value}
        label="Monthly Budget"
        onChange={(e) => onChange(Number.parseInt(String(e.target.value)))}
      >
        {COMBO_BUDGET_CHOICES.map((budget, index) => {
          return (
            <MenuItem key={index} value={budget}>
              ${budget}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
