import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import BottomNavigationLayout from "../BottomNavigationLayout";
import { Box, Grid, Link, Container, Typography } from "@mui/material";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import { Button } from "aagent-ui";
import { Authenticated } from "../authenticator_util";

type FullWidthCardProps = {
  icon: any;
  children: ReactNode;
  to: string;
};

function FullWidthCard({ icon, children, to }: FullWidthCardProps) {
  return (
    <Grid item xs={12}>
      <Link component={RouteLink} to={to} underline="none">
        <Box
          sx={{
            background: "#FFFFFF",
            border: "1px solid rgba(238, 234, 248, 0.75)",
            boxShadow: "0px 5px 40px -11.4039px rgba(229, 233, 242, 0.3)",
            borderRadius: "30px",
            padding: "17px",

            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginRight: "28px", fontSize: "38px" }}>{icon}</Box>
          <Box sx={{ flex: 1 }}>{children}</Box>
          <Box>
            <ArrowForwardIosOutlinedIcon
              sx={{
                height: "20px",
                marginLeft: "20px",
                color: "primary.light",
              }}
            />
          </Box>
        </Box>
      </Link>
    </Grid>
  );
}

export default function Settings({ signOut }: Authenticated) {
  const navigate = useNavigate();

  return (
    <BottomNavigationLayout>
      <Container sx={{ paddingTop: "36px" }}>
        <Typography
          color="secondary.main"
          variant="h1"
          sx={{ marginBottom: "30px" }}
        >
          Settings
        </Typography>

        <Grid container rowSpacing={2} sx={{ marginBottom: "40px" }}>
          {/* Account Information */}
          <FullWidthCard icon={<span>&#128273;</span>} to="/settings/info">
            <Typography variant="h2">Account Information</Typography>
            <Typography variant="subtitle1">
              Edit your personal details
            </Typography>
          </FullWidthCard>

          {/* Manage Locations */}
          <FullWidthCard icon={<span>&#127978;</span>} to="/settings/stores">
            <Typography variant="h2">Manage Locations</Typography>
            <Typography variant="subtitle1">Add or update locations</Typography>
          </FullWidthCard>

          {/* Connect Bank */}
          <FullWidthCard icon={<span>&#128179;</span>} to="/settings/bank-info">
            <Typography variant="h2">Connect Bank</Typography>
            <Typography variant="subtitle1">
              Transfer and manage your funds
            </Typography>
          </FullWidthCard>

          {/* Help */}
          {/* https://www.amp-what.com/unicode/search/question */}
          <FullWidthCard icon={<span>&#10067;</span>} to="/settings/help">
            <Typography variant="h2">Help</Typography>
            <Typography variant="subtitle1">
              Learn how it works or contact us
            </Typography>
          </FullWidthCard>
        </Grid>

        <Button
          onClick={() => {
            signOut();
            navigate("/");
          }}
          fullWidth
          variant="contained"
        >
          LOG OUT
        </Button>
      </Container>
    </BottomNavigationLayout>
  );
}
