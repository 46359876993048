import { Box, Typography } from "@mui/material";

export default function AuthenticationHeader() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        m: { xs: "-140px auto", md: "-170px auto" },
        left: 0,
        right: 0,
      }}
    >
      <Box
        component="img"
        src="/logo192.png"
        sx={{ width: { xs: "70px", md: "90px" }, mb: "12px" }}
      />
      <Typography
        variant="h1"
        sx={{ color: "primary.dark", fontWeight: "normal" }}
      >
        Anonymous Agent
      </Typography>
    </Box>
  );
}
