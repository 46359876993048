import { Box, SxProps } from "@mui/material";
import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { ImgSizes } from "./types";
import { toast } from "aagent-ui";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CircularProgress from "@mui/material/CircularProgress";

type ImageProps = {
  path: string;
  s3?: boolean;
  zoomable?: boolean;
  size?: string;
  sx?: SxProps;
  onClick?: any;
};

export default function Image({
  path,
  s3,
  zoomable,
  size = ImgSizes.og,
  sx,
  ...rest
}: ImageProps) {
  if (path.slice(0, 2) === ImgSizes.og) {
    path = path.replace(ImgSizes.og, size);
  }

  const [src, setSrc] = useState<string>(s3 ? "" : path);
  useEffect(() => {
    if (!s3) return setSrc(path);

    Storage.get(path, {
      level: "public",
      download: true,
    })
      .then((result) => setSrc(URL.createObjectURL(result.Body as Blob)))
      .catch((err) => toast.error(String(err)));
  }, [path, s3]);

  if (!src) {
    // Still loading, show an image icon.
    return (
      <Box
        {...rest}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...(sx || {}),
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const image = <Box {...rest} component="img" src={src} sx={sx} />;
  if (!zoomable) {
    return image;
  }

  return (
    <TransformWrapper>
      <TransformComponent>{image}</TransformComponent>
    </TransformWrapper>
  );
}
