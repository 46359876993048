import {
  Typography,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button, toast } from "aagent-ui";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import { PaymentMethod } from "../types";
import PaymentMethodUI from "../PaymentMethodUI";
import AddPaypalCardForm from "../AddPaypalCardForm";

export default function BankInfo() {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    fetchAPI("/paypal/payment-methods")
      .then((response) => response.json())
      .then(setPaymentMethods)
      .catch((err) => toast.error(String(err)))
      .finally(() => setLoading(false));
  }, [dialogOpen]);

  return (
    <CardWithBottomNavLayout closeLinkTo="/settings">
      <Typography variant="overline">Manage</Typography>
      <Typography variant="h1" sx={{ marginBottom: "32px" }}>
        Payments
      </Typography>
      {loading && <LinearProgress />}

      {paymentMethods.length > 0 && (
        <Typography variant="h2" sx={{ color: "primary.dark" }} gutterBottom>
          Saved Payment Methods
        </Typography>
      )}

      {paymentMethods.map((method) => (
        <PaymentMethodUI
          sx={{ marginY: "20px", cursor: "pointer" }}
          key={method.id}
          isDefault={false}
          paymentMethod={method}
        />
      ))}

      <Button
        variant="contained"
        fullWidth
        onClick={() => setDialogOpen(true)}
        sx={{ marginTop: "30px" }}
      >
        Add new card
      </Button>

      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <DialogTitle>Add Card</DialogTitle>
        <DialogContent>
          <AddPaypalCardForm onSuccess={() => setDialogOpen(false)} />
        </DialogContent>
      </Dialog>
    </CardWithBottomNavLayout>
  );
}
