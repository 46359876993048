// http://jsfiddle.net/vN65r/
import { Box, Container, Link } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import BottomNavigationLayout from "./BottomNavigationLayout";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouteLink } from "react-router-dom";

type CardWithBottomNavLayoutProps = {
  children?: ReactNode;

  // URL to link to when user clicks on the close icon.
  closeLinkTo?: string;
};

function CloseLink({ to }: { to?: string }) {
  const navigate = useNavigate();
  if (!to) {
    return (
      <CloseIcon
        sx={{ color: "primary.light", cursor: "pointer" }}
        onClick={() => {
          try {
            navigate(-1);
          } catch {
            navigate("/");
          }
        }}
      />
    );
  }

  return (
    <Link component={RouteLink} to={to}>
      <CloseIcon sx={{ color: "primary.light" }} />
    </Link>
  );
}

export default function CardWithBottomNavLayout({
  closeLinkTo,
  children,
}: CardWithBottomNavLayoutProps) {
  return (
    <BottomNavigationLayout>
      <Container
        sx={{ paddingTop: { xs: "32px", sm: "64px" }, paddingBottom: "32px" }}
      >
        <Box
          sx={{
            background: "#FFFFFF",
            border: "1px solid rgba(238, 234, 248, 0.75)",
            boxShadow: "0px 0px 40px rgba(158, 160, 194, 0.15)",
            borderRadius: "20px",
            padding: "32px 24px",
            position: "relative",
          }}
        >
          <Box sx={{ position: "absolute", top: "30px", right: "24px" }}>
            <CloseLink to={closeLinkTo} />
          </Box>

          {children}
        </Box>
      </Container>
    </BottomNavigationLayout>
  );
}
