import {
  Box,
  Chip,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import { fetchAPI, postAPI } from "../authenticator_util";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import SliderField from "../SliderField";
import {
  TextField,
  LoadingButton,
  MultiSelect,
  SelectOption,
  getSelectedNames,
  toast,
} from "aagent-ui";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import * as types from "../types";
import CreatePlanHelpDialog from "../CreatePlanHelpDialog";
import AcceptedSocialMedias from "../AcceptedSocialMedias";
import { PLAN_TYPE_TO_LABEL, PUBLIC_SOCIAL_MEDIAS } from "../constants";
import BottomNavigationLayout from "../BottomNavigationLayout";
import ComboPlanDropdown from "../ComboPlanDropdown";
import ComboBudgetDropdown from "../ComboBudgetDropdown";
import PaymentMethodDropdown from "../PaymentMethodDropdown";
import StoreForm from "../StormForm";

function getTierOptions(selectedTiers: types.PlanTier[]): SelectOption[] {
  return Object.values(types.PlanTier).map((tier) => ({
    name: tier,
    label: tier,
    selected: selectedTiers.includes(tier),
  }));
}

const PLAN_TYPE_TO_DEFAULT_FORM_VALUES = {
  [types.PlanType.GUEST]: {
    max_contracts_per_month: 20,
    max_reimbursement_per_bill: 35,
    reimbursement_min: 20,
    reimbursement_max: 100,
  },
  [types.PlanType.GUEST_V2]: {
    max_contracts_per_month: 20,
    // Don't show the reimbursement amount. They are not used for
    // guest v2 plan. We hard-code GUEST v2 for low-bill merchants
    // to have per-tier reimbursement limti to be $10 / $20 / $30.
    max_reimbursement_per_bill: 30,
    reimbursement_min: 10,
    reimbursement_max: 100,
  },
  [types.PlanType.IMPACT]: {
    max_contracts_per_month: 15,
    max_reimbursement_per_bill: 35,
    reimbursement_min: 20,
    reimbursement_max: 150,
  },
  [types.PlanType.FAME]: {
    max_contracts_per_month: 15,
    max_reimbursement_per_bill: 100,
    reimbursement_min: 100,
    reimbursement_max: 100,
  },
  [types.PlanType.COMBO]: {
    max_contracts_per_month: 20,
    max_reimbursement_per_bill: 40,
    reimbursement_min: 20,
    reimbursement_max: 150,
  },
  [types.PlanType.VIP]: {
    max_contracts_per_month: 15,
    max_reimbursement_per_bill: 1000,
    reimbursement_min: 1000,
    reimbursement_max: 1000,
  },
};

function StoreDropdown({
  stores,
  value,
  onChange,
  errorText,
}: {
  value: string[];
  stores: types.Store[];
  errorText: string | string[] | undefined;
  onChange: (val: string[]) => any;
}) {
  const idToBusinessName = Object.fromEntries(
    stores.map((store) => [store.id, store.business_name])
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="store-dropdown-label">Location</InputLabel>
      <Select
        labelId="store-dropdown-label"
        id="store-dropdown"
        size="small"
        label="Locations"
        value={value}
        onChange={(e) => {
          const value = e.target.value;
          const valueAsArray =
            typeof value === "string" ? value.split(",") : value;
          onChange(valueAsArray);
        }}
        error={!!errorText}
        multiple
        renderValue={(selected: string[]) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((storeId) => (
              <Chip key={storeId} label={idToBusinessName[storeId]} />
            ))}
          </Box>
        )}
      >
        {stores.map((store) => (
          <MenuItem key={store.id} value={store.id}>
            {store.business_name}
          </MenuItem>
        ))}
      </Select>
      {!!errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
}

function CreatePlanWithType({
  user,
  stores,
}: {
  user: types.User;
  stores: types.Store[];
}) {
  const { planType: plan_type } = useParams() as { planType: types.PlanType };
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formDefaultsForPlanType = PLAN_TYPE_TO_DEFAULT_FORM_VALUES[plan_type];
  const initialValues: types.PlanForm = {
    plan_name: `My ${PLAN_TYPE_TO_LABEL[plan_type]} Plan`,
    max_contracts_per_month: formDefaultsForPlanType.max_contracts_per_month,
    max_reimbursement_per_bill:
      formDefaultsForPlanType.max_reimbursement_per_bill,
    social_medias: PUBLIC_SOCIAL_MEDIAS,
    review_platforms: Object.values(types.ReviewPlatform),
    tiers: [types.PlanTier.ENTRY, types.PlanTier.STANDARD],
    store_ids: [stores[0].id as string],
    notes: "",
    combo_type:
      plan_type === types.PlanType.COMBO ? types.ComboType.BALANCE : undefined,
    combo_monthly_budget: types.PlanType.COMBO ? 1000 : undefined,
    payment_method_id: undefined,
  };

  const submitPlan = (rawValues: types.PlanForm) => {
    const values = {
      ...types.planFormSchema.cast(rawValues),
      plan_type: types.PlanType[plan_type],
    };

    postAPI("/plan", values)
      .then((response) => response.json())
      .then((plan) => {
        setIsSubmitting(false);
        navigate(`/plan/${plan.id}`);
      });

    setIsSubmitting(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: types.planFormSchema,
    onSubmit: submitPlan,
  });

  const { values, errors, setFieldValue } = formik;
  const hasErrors = Object.keys(errors).length > 0;

  const setTierOptions = (options: SelectOption[]) => {
    setFieldValue("tiers", getSelectedNames(options));
  };
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  return (
    <CardWithBottomNavLayout closeLinkTo="/">
      <Typography variant="overline" sx={{ color: "primary.light" }}>
        PLAN DETAILS
      </Typography>
      <Typography
        variant="h1"
        sx={{ color: "secondary.main", marginBottom: "32px" }}
      >
        {PLAN_TYPE_TO_LABEL[plan_type]}
      </Typography>

      <FormikProvider value={formik}>
        <Container
          component="form"
          noValidate
          sx={{ maxWidth: { xs: "600px" } }}
          onSubmit={formik.handleSubmit}
        >
          {/* plan name */}
          <TextField
            fullWidth
            label="Plan name"
            name="plan_name"
            value={values.plan_name}
            variant="outlined"
            onChange={formik.handleChange}
            size="small"
          />
          <Box sx={{ m: "20px" }}></Box>

          {/* Store id */}
          <StoreDropdown
            stores={stores}
            value={values.store_ids}
            errorText={errors.store_ids}
            onChange={(storeIds) => formik.setFieldValue("store_ids", storeIds)}
          />
          <Box m="20px" />

          {/* Payment method dropdown */}
          <PaymentMethodDropdown
            value={values.payment_method_id || ""}
            onChange={(paymentMethodId) =>
              formik.setFieldValue("payment_method_id", paymentMethodId)
            }
          />
          <Box m="20px" />

          {/* combo type selection */}
          {plan_type === types.PlanType.COMBO && (
            <>
              <ComboPlanDropdown
                value={values.combo_type as types.ComboType}
                onChange={(comboType) =>
                  formik.setFieldValue("combo_type", comboType)
                }
              />
              <Box m="20px" />
            </>
          )}

          {/* Combo budget selection */}
          {plan_type === types.PlanType.COMBO && (
            <>
              <ComboBudgetDropdown
                value={values.combo_monthly_budget as number}
                onChange={(budget) =>
                  formik.setFieldValue("combo_monthly_budget", budget)
                }
              />
              <Box m="20px" />
            </>
          )}

          {/* max_contracts_per_month */}
          {plan_type !== types.PlanType.COMBO && (
            <SliderField
              label="Max contracts per month"
              name="max_contracts_per_month"
              value={values.max_contracts_per_month}
              setFieldValue={setFieldValue}
              step={1}
              min={0}
              max={100}
            />
          )}

          <Box sx={{ m: "20px" }}></Box>

          {/* max_reimbursement_per_bill */}
          {[types.PlanType.GUEST, types.PlanType.IMPACT].includes(
            plan_type
          ) && (
            <SliderField
              label="Max Reimbursement / Bill"
              name="max_reimbursement_per_bill"
              value={values.max_reimbursement_per_bill}
              setFieldValue={setFieldValue}
              step={5}
              min={formDefaultsForPlanType.reimbursement_min}
              max={formDefaultsForPlanType.reimbursement_max}
            />
          )}
          <Box sx={{ m: "20px" }} />

          {/* Select pricing tier */}
          {[
            types.PlanType.GUEST_V2,
            types.PlanType.IMPACT,
            types.PlanType.FAME,
          ].includes(plan_type) && (
            <MultiSelect
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Influencer Tier
                  <HelpOutlineIcon
                    onClick={() => setOpenHelpDialog(true)}
                    sx={{ fontSize: "16px", marginLeft: "6px" }}
                  />
                </Box>
              }
              options={getTierOptions(values.tiers)}
              onSelect={setTierOptions}
              error={formik.errors.tiers}
            />
          )}
          <Box sx={{ m: "26px" }} />

          {plan_type !== types.PlanType.GUEST && (
            <AcceptedSocialMedias
              value={formik.values.social_medias}
              onChange={(medias) =>
                formik.setFieldValue("social_medias", medias)
              }
            />
          )}

          <Box sx={{ m: "26px" }} />
          <TextField
            fullWidth
            variant="standard"
            label="Special Notes (optional)"
            name="notes"
            value={values.notes}
            onChange={formik.handleChange}
            size="small"
          />

          <Box sx={{ m: "40px" }}></Box>
          <Box>
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              fullWidth
              disabled={hasErrors}
            >
              Create Plan
            </LoadingButton>
          </Box>

          <Box sx={{ m: "30px" }}></Box>
        </Container>
      </FormikProvider>

      <CreatePlanHelpDialog
        open={openHelpDialog}
        onClose={() => setOpenHelpDialog(false)}
        planType={plan_type}
      />
    </CardWithBottomNavLayout>
  );
}

export default function CreatePlanWithTypePage({ user }: { user: types.User }) {
  const [loadingStores, setLoadingStores] = useState<boolean>(false);
  const [stores, setStores] = useState<types.Store[]>([]);
  const [merchant, setMerchant] = useState<types.MerchantInfo>();

  useEffect(() => {
    setLoadingStores(true);
    fetchAPI("/merchant/stores")
      .then((res) => res.json())
      .then(setStores)
      .catch((err) => toast.error(String(err)))
      .finally(() => setLoadingStores(false));

    fetchAPI("/merchant/info")
      .then((res) => res.json())
      .then(setMerchant)
      .catch((err) => toast.error(String(err)));
  }, []);

  if (loadingStores || !merchant) {
    return (
      <BottomNavigationLayout>
        <LinearProgress />
      </BottomNavigationLayout>
    );
  }

  if (stores.length === 0) {
    return (
      <CardWithBottomNavLayout closeLinkTo="/">
        <Typography variant="h1" gutterBottom>
          Add Location
        </Typography>

        <StoreForm
          user={user}
          merchant={merchant}
          onSuccess={(store) => setStores([store])}
        />
      </CardWithBottomNavLayout>
    );
  }

  return <CreatePlanWithType user={user} stores={stores} />;
}
