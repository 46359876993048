import * as React from "react";
import { isMobile } from "react-device-detect";
import { Box } from "@mui/material";
import { ImgSizes } from "./types";
import Carousel from "react-material-ui-carousel";
import Image from "./Image";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

export default function ImageCarouselWithModal({
  imageArray,
  isS3Image,
}: {
  imageArray: string[];
  isS3Image: boolean;
}) {
  const [imgIndex, setImgIndex] = React.useState<number | undefined>(0);
  const [curImgIndex, setCurImgIndex] = React.useState<number | undefined>(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setCurImgIndex(imgIndex);
  };
  const handleClose = () => {
    setOpen(false);
    setCurImgIndex(imgIndex);
  };

  const height = isMobile ? "300px" : "500px";
  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Carousel
        onChange={(now) => setImgIndex(now)}
        index={curImgIndex}
        swipe={true}
        autoPlay={false}
        animation="slide"
        height={height}
        sx={{ flex: "1 0 auto" }}
        indicators={imageArray.length > 1}
      >
        {imageArray.map((src: string, index: number) => (
          <Box key={index} sx={{ display: "flex", justifyContent: "center" }}>
            <Image
              path={src}
              s3={isS3Image}
              size={isMobile ? ImgSizes.md : ImgSizes.lg}
              onClick={handleOpen}
              sx={{ maxHeight: height, maxWidth: "100%" }}
            />
          </Box>
        ))}
      </Carousel>

      {/* Modal */}
      <Modal
        BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.9)" } }}
        open={open}
        onClose={handleClose}
      >
        <Box>
          <CloseIcon
            onClick={handleClose}
            sx={{ width: 34, height: 34, color: "white" }}
          />
          <Carousel
            onChange={(now) => setImgIndex(now)}
            index={curImgIndex}
            indicators={false}
            swipe={false}
            autoPlay={false}
            animation="slide"
            navButtonsAlwaysVisible={true}
            height={"calc(var(--vh, 1vh) * 85)"}
          >
            {imageArray.map((src: string, index: number) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Image
                  path={src}
                  s3={isS3Image}
                  zoomable
                  size={ImgSizes.og}
                  sx={{
                    objectFit: "contain",
                    maxHeight: "calc(var(--vh, 1vh) * 85)",
                    maxWidth: "100%",
                  }}
                />
              </Box>
            ))}
          </Carousel>
        </Box>
      </Modal>
    </Box>
  );
}
