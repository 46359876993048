import { Box, Grid, TextField } from "@mui/material";
import { LoadingButton, toast } from "aagent-ui";
import { useFormik } from "formik";
import { ChangeEvent, useState } from "react";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import { postAPI } from "./authenticator_util";
import PaypalImage from "./assets/paypal.png";
import images from "react-payment-inputs/images";

const INITIAL_VALUES = {
  cardHolder: "",
  cardNumber: "",
  expiryDate: "",
  cvc: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipcode: "",
  },
};

function ErrorMessage({ errors, touched }: any) {
  const touchedAny = touched.cardNumber || touched.expiryDate || touched.cvc;

  if (!touchedAny && !!errors.cardNumber)
    return (
      <Box sx={{ color: "#c9444d", fontSize: "0.75rem" }}>
        {errors.cardNumber}
      </Box>
    );
  if (!touchedAny && !!errors.expiryDate)
    return (
      <Box sx={{ color: "#c9444d", fontSize: "0.75rem" }}>
        {errors.expiryDate}
      </Box>
    );
  if (!touchedAny && !!errors.cvc)
    return (
      <Box sx={{ color: "#c9444d", fontSize: "0.75rem" }}>{errors.cvc}</Box>
    );
  return null;
}

type AddPaypalCardFormProps = {
  onSuccess?: () => any;
};

export default function AddPaypalCardForm({
  onSuccess,
}: AddPaypalCardFormProps) {
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    wrapperProps,
  } = usePaymentInputs();
  const [loading, setLoading] = useState<boolean>(false);

  const submitForm = (data: typeof INITIAL_VALUES) => {
    setLoading(true);

    const [mm, yy] = data.expiryDate.split(" / ");
    const payload = {
      number: data.cardNumber.replaceAll(" ", ""),
      expiry: `20${yy}-${mm}`,
      name: data.cardHolder,
      billing_address: {
        address_line_1: data.address.line1,
        address_line_2: data.address.line2,
        admin_area_1: data.address.state,
        admin_area_2: data.address.city,
        postal_code: data.address.zipcode,
        country_code: "US",
      },
    };

    postAPI("/paypal/add-card", payload)
      .then(() => toast.success("Card added"))
      .then(() => !!onSuccess && onSuccess())
      .catch((err) =>
        toast.error({
          text: String(err),
          autotimeout: 7000,
        })
      )
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitForm,
    validate: () => {
      let errors: any = {};
      if (meta.erroredInputs.cardNumber) {
        errors.cardNumber = meta.erroredInputs.cardNumber;
      }
      if (meta.erroredInputs.expiryDate) {
        errors.expiryDate = meta.erroredInputs.expiryDate;
      }
      if (meta.erroredInputs.cvc) {
        errors.cvc = meta.erroredInputs.cvc;
      }
      return errors;
    },
  });

  const cvcProps = getCVCProps({
    onBlur: formik.handleBlur,
    onChange: (e: ChangeEvent<any>) => {
      if (e.target.value.length <= 3) formik.handleChange(e);
    },
  });
  const expiryProps = getExpiryDateProps({
    onBlur: formik.handleBlur,
    // Handles auto focus on csv already.
    onChange: formik.handleChange,
  });
  const cardNumberProps = getCardNumberProps({
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
  });

  const paymentStyles: any = {
    fieldWrapper: { base: { width: "100%" } },
    inputWrapper: { base: { height: "56px" } },
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaymentInputsWrapper {...wrapperProps} styles={paymentStyles}>
            <svg {...getCardImageProps({ images })} />
            <input
              {...cardNumberProps}
              style={{ flex: "4 3 auto", minWidth: 0, marginRight: "8px" }}
            />
            <input
              {...expiryProps}
              // value={formik.values.expiryDate}
              style={{ flex: "1 1 auto", minWidth: "20px", marginRight: "4px" }}
            />
            <input
              {...cvcProps}
              // value={formik.values.cvc}
              style={{ flex: "1 1 auto", minWidth: "20px" }}
            />
          </PaymentInputsWrapper>
          <ErrorMessage errors={formik.errors} touched={meta.touchedInputs} />
        </Grid>

        {/* Card holder name */}
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="cardHolder"
            label="Card Holder Name"
            value={formik.values.cardHolder}
            onChange={formik.handleChange}
          />
        </Grid>

        {/* Address */}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            name="address.line1"
            label="Street Address 1"
            value={formik.values.address.line1}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="address.line2"
            label="Street Address 2 (optional)"
            value={formik.values.address.line2}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            name="address.city"
            label="City"
            value={formik.values.address.city}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            name="address.state"
            label="State"
            value={formik.values.address.state}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            name="address.zipcode"
            label="Zipcode"
            value={formik.values.address.zipcode}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>

      <Box m="20px" />
      <LoadingButton
        loading={loading}
        type="submit"
        fullWidth
        variant="contained"
      >
        Add card
      </LoadingButton>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "24px",
          color: "#aaa",
          fontSize: "16px",
          alignItems: "center",
          fontStyle: "italic",
        }}
      >
        Powered by{" "}
        <img
          alt="paypal"
          src={PaypalImage}
          style={{ height: "16px", marginLeft: "6px", marginTop: "2px" }}
        />
      </Box>
    </form>
  );
}
