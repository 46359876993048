import { Box, LinearProgress, SxProps, Typography } from "@mui/material";
import { Button, toast } from "aagent-ui";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAPI } from "../authenticator_util";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import StoreIcon from "@mui/icons-material/Store";
import IconCard from "../IconCard";
import * as types from "../types";

function StoreCard({ sx, store }: { sx: SxProps; store: types.Store }) {
  const navigate = useNavigate();

  return (
    <IconCard
      sx={sx}
      icon={<StoreIcon color="primary" />}
      onClick={() => navigate(`/settings/store/${store.id}`)}
    >
      <Typography>{store.business_name}</Typography>
      <Typography sx={{ color: "text.secondary" }}>
        {store.address.city}, {store.address.state} {store.address.zipcode}
      </Typography>
    </IconCard>
  );
}

export default function StoresInfo() {
  const [stores, setStores] = useState<types.Store[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchAPI("/merchant/stores")
      .then((response) => response.json())
      .then(setStores)
      .catch((err) => toast.error(String(err)))
      .finally(() => setLoading(false));
  }, []);

  return (
    <CardWithBottomNavLayout closeLinkTo="/settings">
      <Typography variant="overline">Manage</Typography>
      <Typography variant="h1" sx={{ marginBottom: "32px" }}>
        Locations
      </Typography>

      {loading && <LinearProgress />}

      <Box>
        {stores.map((store) => (
          <StoreCard
            key={store.id}
            store={store}
            sx={{ marginY: "20px", cursor: "pointer" }}
          />
        ))}
      </Box>

      <Box m="40px" />
      <Button fullWidth onClick={() => navigate("/settings/stores/add")}>
        Add Location
      </Button>
    </CardWithBottomNavLayout>
  );
}
