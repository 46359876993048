import { theme } from "aagent-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as types from "./types";

export default function MediaIcon({
  icon,
  selected,
  onClick,
  sx,
}: {
  icon: types.IconDefinition | types.SvgIcon;
  selected?: boolean;
  onClick?: any;
  sx?: any;
}) {
  const color = selected ? theme.palette.primary.dark : theme.palette.grey[200];
  if ((icon as types.IconDefinition).iconName !== undefined) {
    const faIcon = icon as types.IconDefinition;
    return (
      <FontAwesomeIcon
        onClick={onClick}
        icon={faIcon}
        color={color}
        style={{ fontSize: "20px", ...(sx || {}) }}
      />
    );
  }

  const SvgIcon = icon as types.SvgIcon;
  return (
    <SvgIcon
      sx={{ fontSize: "20px", color, ...(sx || {}) }}
      onClick={onClick}
    />
  );
}
