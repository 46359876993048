import * as types from "./types";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const COMBO_TYPE_TO_REV_LIKES = {
  [types.ComboType.REVENUE]: { revenue: 1.9, likes: 1.0 },
  [types.ComboType.BALANCE]: { revenue: 1.6, likes: 1.15 },
  [types.ComboType.MARKETING]: { revenue: 1.2, likes: 1.45 },
};

type ComboPlanDropdownProps = {
  value: types.ComboType;
  onChange: (val: string) => any;
};

export default function ComboPlanDropdown({
  value,
  onChange,
}: ComboPlanDropdownProps) {
  return (
    <FormControl fullWidth>
      <InputLabel id="combo-dropdown-label">Combo Type</InputLabel>
      <Select
        labelId="combo-dropdown-label"
        id="combo-dropdown"
        size="small"
        value={value}
        label="Combo Type"
        onChange={(e) => onChange(e.target.value)}
      >
        {Object.values(types.ComboType).map((comboType, index) => {
          const { revenue, likes } = COMBO_TYPE_TO_REV_LIKES[comboType];
          return (
            <MenuItem key={index} value={comboType}>
              {comboType}: {revenue}x Revenue, {likes}x Likes
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
