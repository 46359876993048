import * as types from "./types";

type PaymentMethodDisplayInfo = {
  brand: string;
  last4: string;
};

export function getPaymentMethodDisplayInfo(
  method: types.PaymentMethod
): PaymentMethodDisplayInfo {
  let brand = "unknown";
  let last4 = "";
  switch (method.type) {
    case "card":
      brand = (method.card as types.Card).brand;
      last4 = (method.card as types.Card).last4;
      break;
    case "us_bank_account":
      brand = (method.us_bank_account as types.USBankAccount).bank_name;
      last4 = (method.us_bank_account as types.USBankAccount).last4;
      break;
    default:
      throw Error(`Unknown payment method type: ${method.type}`);
  }

  return { brand, last4 };
}
